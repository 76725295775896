













































































import Avatar from '../../../shared/components/Avatar.vue';
import { PropType } from 'vue';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';
import ContactInformationContactForm from './ContactInformationContactForm.vue';
import { InstitutionProfileMasterDataForm } from '../../business/institutionProfileMasterDataForm';
import * as dateUtil from '../../../shared/utils/dateUtil.js';
import AulaButtons from '../../../shared/components/AulaButtons.vue';
import AulaButton from '../../../shared/components/AulaButton.vue';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import { lockStatusEnum } from '../../../shared/enums/lockStatusEnum.js';
import ContactInformationConsents from './ContactInformationConsents.vue';
import ContactInformationAdditionalConsents from './ContactInformationAdditionalConsents.vue';
import ContactInformationProfileActions from './ContactInformationProfileActions.vue';
import { getContactName } from '../../business/profileHelpers';

export default {
  components: {
    ContactInformationProfileActions,
    ContactInformationAdditionalConsents,
    ContactInformationConsents,
    Icon,
    AulaButton,
    AulaButtons,
    ContactInformationContactForm,
    Avatar,
  },
  props: {
    profile: { type: Object as PropType<InstitutionProfileModel>, required: true },
    isEditing: { type: Boolean, default: false },
    showConsents: { type: Boolean, default: false },
    showAdditionalConsents: { type: Boolean, default: false },
    showShortcuts: { type: Boolean, default: false },
    showBlockedStatus: { type: Boolean, default: false },
  },
  emits: ['input', 'deleteAvatar', 'editAvatar', 'toggleBlockedCommunication'],
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    addressStreet() {
      if (this.profile.address.postalDistrict == null) {
        return this.$options.filters.fromTextKey('PROFILE_ADDRESS_UNKOWN');
      }
      return this.profile.address.street;
    },
    birthday() {
      if (!this.profile.birthday) {
        return '';
      }
      return dateUtil.format(this.profile.birthday, 'D[.] MMM[.] YYYY');
    },
    lastActivity() {
      if (!this.profile.lastActivity) {
        return '';
      }
      return dateUtil.format(this.profile.lastActivity, 'D[.] MMM[.] YYYY');
    },
    canDeleteAvatar() {
      return this.profile.currentUserCanDeleteProfilePicture;
    },
    canEditAvatar() {
      return this.profile.currentUserCanEditProfilePicture;
    },
    canViewDescription() {
      return this.profile.currentUserCanSeeProfileDescription;
    },
    hasMainDetails() {
      return this.profile.address || this.profile.birthday || this.lastActivity || this.canViewDescription;
    },
    isLocked() {
      return this.profile.profileStatus === lockStatusEnum.LOCKED;
    },
    contactName() {
      return getContactName(this.profile);
    },
  },
  methods: {
    emitInput(form: InstitutionProfileMasterDataForm) {
      this.$emit('input', form);
    },
    emitDeleteAvatar() {
      this.$emit('deleteAvatar', this.profile);
    },
    emitEditAvatar() {
      this.$emit('editAvatar', this.profile);
    },
    emitToggleBlockedCommunication(state) {
      this.$emit('toggleBlockedCommunication', state);
    },
  },
};
