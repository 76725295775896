




















import FileSelect from '../../../shared/components/FileSelect.vue';
import AvatarUploadPreview from './AvatarUploadPreview.vue';
import { FileValidationService } from '../../../shared/services/fileValidation.service';
import { imageExtensions } from '../../../shared/enums/imageExtensions';
import { mediaTypes } from '../../../shared/enums/mediaTypes';
import { PropType } from 'vue';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';
import AulaAlert from '../../../shared/components/AulaAlert.vue';
import { centralConfigurationService } from '../../../shared/services/api/centralConfiguration.service';

export default {
  components: { AulaAlert, AvatarUploadPreview, FileSelect },
  props: {
    profile: { type: Object as PropType<InstitutionProfileModel>, required: true },
  },
  emits: ['change'],
  data() {
    return {
      selectedImageFile: null,
      rotationAngle: 0,
      maxFileSize: null,
    };
  },
  computed: {
    formats() {
      return imageExtensions;
    },
    formatList() {
      return this.formats.join(', ');
    },
    validator() {
      return new FileValidationService(this.formats, this.maxFileSize);
    },
    selectedMedia() {
      if (!this.selectedImageFile) {
        return null;
      }

      return {
        name: this.selectedImageFile.name,
        creator: { id: this.profile.id },
        media: {
          file: this.selectedImageFile,
          mediaType: mediaTypes.IMAGE,
          rotationDegree: this.rotationAngle,
        },
        size: this.selectedImageFile.size,
        isLoading: false,
      };
    },
  },
  mounted() {
    this.getMaxFileSize();
  },
  methods: {
    async getMaxFileSize() {
      this.maxFileSize = await centralConfigurationService.getMaxFileSize();
    },
    handleFileSelected(files: File[]) {
      const file = files[0];
      if (!file || !this.validateFiles(file)) {
        return;
      }
      this.selectedImageFile = file;
      this.$emit('change', this.selectedMedia);
    },
    updateRotateAngle(angle) {
      this.rotationAngle = angle;
      this.$emit('change', this.selectedMedia);
    },
    deleteImage() {
      this.selectedImageFile = null;
      this.$emit('change', this.selectedMedia);
    },
    validateFiles(file: File) {
      if (this.validator.validateSize(file) === false) {
        this.$refs.fileSizeWarningModal.show();
        return false;
      }
      if (this.validator.validateExtension(file) === false) {
        this.$refs.fileFormatWarningModal.show();
        return false;
      }
      return true;
    },
  },
};
