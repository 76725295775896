<template>
  <div v-if="!isMobile && showMultiProfileTable" class="consent-table">
    <AulaButtons v-if="showSubmit" class="consent-actions">
      <AulaButton variant="link" @click="emitCancel">
        {{ 'CANCEL' | fromTextKey }}
      </AulaButton>
      <AulaButton :disabled="isUpdating" class="text-uppercase" @click="handleSaveClicked">
        {{ 'BUTTON_SAVE' | fromTextKey }}
      </AulaButton>
    </AulaButtons>
    <div v-if="!isEditOnBehalfMode" class="header">
      <h1 class="title">
        <template v-if="showTitle">
          <template v-if="type === 'consent'">
            {{ 'CONSENT_LABEL' | fromTextKey }}
          </template>
          <template v-else>
            {{ 'ADDITIONAL_LABEL' | fromTextKey }}
          </template>
        </template>
      </h1>
      <div v-for="(profile, profileIndex) in profiles" :key="profileIndex" class="profile">
        <user-icon
          :id="profile.institutionProfile.id"
          :key="profile.institutionProfile.id"
          :name="profile.institutionProfile.name"
          :picture-object="profile.institutionProfile.profilePicture"
          :short-name="profile.institutionProfile.shortName"
          :show-inline="true"
        />
        <div class="profile-name">
          {{ profile.institutionProfile.name.split(' ')[0] }}
          <template v-if="profile.institutionProfile.mainGroup != null">
            ({{ profile.institutionProfile.mainGroup }})
          </template>
          <div class="instition-name">
            {{ profile.institutionProfile.institutionName }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isDisabled && !isEditOnBehalfMode" class="functions">
      <h1 class="title" />
      <div v-for="(profile, profileIndex) in profiles" :key="profileIndex" class="profile">
        <b-form-radio-group
          v-if="profile.isCheckAllShown"
          v-model="profile.selectAll"
          :disabled="isDisabled"
          @change.native="checkedAll(profileIndex, $event)"
        >
          <b-form-radio value="yes">
            <span aria-hidden="true">{{ 'YES_ALL' | fromTextKey }}</span
            ><span class="sr-only">{{
              'ARIA_LABEL_CHOOSE_ALL_YES'
                | fromTextKey({
                  profileName: profile.institutionProfile.name,
                  institutionName: profile.institutionProfile.institutionName,
                })
            }}</span>
          </b-form-radio>
          <b-form-radio value="no">
            <span aria-hidden="true">{{ 'NO_ALL' | fromTextKey }}</span
            ><span class="sr-only">{{
              'ARIA_LABEL_CHOOSE_ALL_NO'
                | fromTextKey({
                  profileName: profile.institutionProfile.name,
                  institutionName: profile.institutionProfile.institutionName,
                })
            }}</span>
          </b-form-radio>
        </b-form-radio-group>
      </div>
    </div>
    <consent-answer
      v-if="type == 'consent'"
      :is-disabled="isDisabled"
      :multiple-columns="!isMobile && showMultiProfileTable"
      :is-mobile="isMobile"
      :is-submitted="isSubmitted"
    />
    <additional-answer
      v-else
      :multiple-columns="!isMobile && showMultiProfileTable"
      :is-submitted="isSubmitted"
      :is-disabled="isDisabled"
      :is-revision-history-view-mode="isRevisionHistoryViewMode"
      :profiles="getProfiles"
      :questions="getQuestions"
      :is-edit-on-behalf-mode="isEditOnBehalfMode"
      :is-mobile="isMobile"
    />
  </div>
  <div v-else-if="(!isMobile && !showMultiProfileTable) || isMobile">
    <div v-for="(profile, index) in profiles" :key="index" class="consent-mobile">
      <div class="header">
        <div v-if="isMobile" class="instition-name">
          {{ profile.institutionProfile.institutionName }}
        </div>
        <div class="profile mb-3">
          <user-icon
            :id="profile.institutionProfile.id"
            :key="profile.institutionProfile.id"
            :name="profile.institutionProfile.name"
            :short-name="profile.institutionProfile.shortName"
            :picture-object="profile.institutionProfile.profilePicture"
            :show-inline="true"
          />
          <div class="profile-name">
            {{ profile.institutionProfile.name.split(' ')[0] }}
            <template v-if="profile.institutionProfile.mainGroup != null">
              ({{ profile.institutionProfile.mainGroup }})
            </template>
            <div v-if="!isMobile" class="instition-name">
              {{ profile.institutionProfile.institutionName }}
            </div>
          </div>
        </div>
        <hr v-if="isMobile" />
        <div v-if="!isDisabled && profile.isCheckAllShown" class="function" :class="{ mobile: isMobile }">
          <div v-if="isMobile" class="filter-all">
            {{ 'FILTER_ALL' | fromTextKey }}
          </div>
          <b-form-radio-group
            v-if="!isEditOnBehalfMode"
            v-model="profile.selectAll"
            @change.native="checkedAll(index, $event)"
          >
            <b-form-radio value="yes">
              <span aria-hidden="true">{{ 'YES' | fromTextKey }}</span
              ><span class="sr-only">{{
                'ARIA_LABEL_CHOOSE_ALL_YES'
                  | fromTextKey({
                    profileName: profile.institutionProfile.name,
                    institutionName: profile.institutionProfile.institutionName,
                  })
              }}</span>
            </b-form-radio>
            <b-form-radio value="no">
              <span aria-hidden="true">{{ 'NO' | fromTextKey }}</span
              ><span class="sr-only">{{
                'ARIA_LABEL_CHOOSE_ALL_NO'
                  | fromTextKey({
                    profileName: profile.institutionProfile.name,
                    institutionName: profile.institutionProfile.institutionName,
                  })
              }}</span>
            </b-form-radio>
          </b-form-radio-group>
        </div>
      </div>
      <consent-answer
        v-if="type == 'consent'"
        :profile-index="index"
        :is-disabled="isDisabled"
        :multiple-columns="false"
        :is-mobile="isMobile"
        :is-submitted="isSubmitted"
      />
      <additional-answer
        v-else
        :profile="profile"
        :profile-index="index"
        :multiple-columns="false"
        :is-submitted="isSubmitted"
        :is-mobile="isMobile"
        :is-revision-history-view-mode="isRevisionHistoryViewMode"
        :is-edit-on-behalf-mode="isEditOnBehalfMode"
        :is-disabled="isDisabled"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { mapActions, mapGetters } from 'vuex';
import { types } from '../../../src/store/types/types';
import { additionalDataGrossListEnum } from '../../enums/additionalDataGrossList';
import { consentNumber } from '../../enums/consentNumber';
import { consentAnswerType } from '../../enums/consentAnswerType';
import AdditionalAnswer from './AdditionalAnswer';
import ConsentAnswer from './ConsentAnswer';
import { consentResponseStatus } from '../../enums/consentResponseStatus';
import { portalRoles } from '../../enums/portalRoles';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import AulaButtons from '../AulaButtons.vue';
import AulaButton from '../AulaButton.vue';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
    data: { type: Array, default: null },
    type: { type: String, default: 'consent' },
    isDisabled: { type: Boolean, default: false },
    isRevisionHistoryViewMode: { type: Boolean, default: false },
    isEditOnBehalfMode: { type: Boolean, default: false },
    showTitle: { type: Boolean, default: true },
    showSubmit: { type: Boolean, default: false },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      profiles: [],
      originalDataProfiles: [],
      additionalDataResponses: this.additionalData,
      showWarning: true,
      profilesHaveBeenCloned: false,
      additionalDataGrossListEnum: additionalDataGrossListEnum,
      additionalData: [],
      consentNumber: consentNumber,
      consentAnswerType: consentAnswerType,
      portalRoles: portalRoles,
      questions: [],
      consents: [],
      consentData: [],
      consentForLeavingInstitution: [consentNumber.NUMBER_2, consentNumber.NUMBER_27, consentNumber.NUMBER_36],
      isSubmitted: false,
      respondedQuestions: [],
      isUpdating: false,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      loggedInProfile: types.GET_CURRENT_PROFILE,
    }),
    maxProfileOnTable() {
      if (this.windowWidth > 992) {
        return 3;
      }
      if (this.windowWidth > 768) {
        return 2;
      }
      return 1;
    },
    showMultiProfileTable() {
      return this.profiles && this.profiles.length <= this.maxProfileOnTable;
    },
    getProfiles() {
      return this.profiles;
    },
    getQuestions() {
      return this.questions;
    },
  },
  watch: {
    profiles: {
      handler: function () {
        if (this.type == 'consent') {
          this.showWarning = this.validateConsentAnswered();
          this.populateSelectAll();
        } else {
          this.showWarning = this.validateAdditionalAnswered();
          this.populateSelectAll();
        }
        if (!this.profilesHaveBeenCloned) {
          this.originalDataProfiles = cloneDeep(this.profiles);
          this.profilesHaveBeenCloned = true;
        }
      },
      deep: true,
    },
    data: {
      handler: function (val, oldVal) {
        if (!isEqual(val, oldVal)) {
          this.type == 'consent' ? this.initConsent() : this.initAdditionalData();
        }
      },
      deep: true,
    },
    showWarning: function (val) {
      this.$emit('showWarning', val);
    },
    isRevisionHistoryViewMode: function () {
      this.initAdditionalData();
    },
  },
  mounted() {
    const em = this;
    const checkExist = setInterval(function () {
      if (em.data != null) {
        if (em.type == 'consent') {
          em.initConsent();
        } else {
          em.initAdditionalData();
        }
        clearInterval(checkExist);
      }
    }, 100);
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    ...mapActions({
      updateAdditionalData: types.UPDATE_ADDITIONAL_DATA_ON_BEHALF,
    }),
    emitCancel() {
      this.$emit('cancel');
    },
    handleSaveClicked() {
      if (!this.validateAdditionalAnswered(true)) {
        return;
      }
      const payload = this.submitAdditionalTableOnBehalf();
      this.isUpdating = true;
      this.updateAdditionalData(payload).then(() => {
        this.isUpdating = false;
        this.$emit('saved');
      });
    },
    resetTable() {
      if (this.type == 'consent') {
        this.consentData = this.data;
        this.profiles = cloneDeep(this.originalDataProfiles);
      } else {
        this.initAdditionalData();
      }
    },
    isTableEdited() {
      return !isEqual(this.originalDataProfiles, this.profiles);
    },
    declinedSpecialQuestionEdited() {
      const declinedConsentIds = [];
      if (this.type == 'consent') {
        let index = 0;
        for (const profile of this.profiles) {
          for (const consent in profile.consents) {
            if (
              this.consentForLeavingInstitution.includes(profile.consents[consent].consentId) &&
              profile.consents[consent].consentResponseAnswer == profile.consents[consent].allowedAnswers[1] &&
              this.originalDataProfiles[index].consents[consent].consentResponseAnswer !=
                consentAnswerType.NOT_AT_ALL &&
              (this.originalDataProfiles[index].consents[consent].consentResponseAnswer ==
                profile.consents[consent].allowedAnswers[0] ||
                this.originalDataProfiles[index].consents[consent].consentResponseAnswer == null) &&
              declinedConsentIds.filter(
                c =>
                  c.consentId == profile.consents[consent].consentId &&
                  c.previousAnswer == this.originalDataProfiles[index].consents[consent].consentResponseAnswer
              ).length == 0
            ) {
              if (profile.consents[consent].consentId == consentNumber.NUMBER_2) {
                if (!this.$route.path.includes('onboarding')) {
                  declinedConsentIds.push({
                    consentId: profile.consents[consent].consentId,
                    previousAnswer: this.originalDataProfiles[index].consents[consent].consentResponseAnswer,
                  });
                }
              } else {
                declinedConsentIds.push({
                  consentId: profile.consents[consent].consentId,
                  previousAnswer: this.originalDataProfiles[index].consents[consent].consentResponseAnswer,
                });
              }
            }
          }
          index++;
        }
      }
      return declinedConsentIds;
    },
    filterProfiles() {
      const profiles = [];
      this.respondedQuestions = [];
      for (const instProfile of this.additionalData) {
        const questions = {};
        let isCheckAllShown = false;
        const respondedQuestionIds = [];
        for (const additional of instProfile.additionalDataResponses) {
          let yesNoResponse = null;
          let responding = null;
          if (additional.answerType == additionalDataGrossListEnum.YESNO) {
            isCheckAllShown = true;
            if (additional.yesNoResponse != null) {
              respondedQuestionIds.push(additional.additionalDataId);
              yesNoResponse = additional.yesNoResponse == true ? 'yes' : 'no';
              responding = additional.response;
            }
          }
          questions[additional.additionalDataId] = {
            additionalDataId: additional.additionalDataId,
            additionalDataResponseId: additional.additionalDataResponseId,
            additionalDataQuestion: additional.additionalDataQuestion,
            optionalQuestionForNo: additional.optionalQuestionForNo,
            optionalQuestionForYes: additional.optionalQuestionForYes,
            response: additional.response,
            responding: responding,
            answerType: additional.answerType,
            yesNoResponse: yesNoResponse,
            updatedAt: additional.updatedAt != null ? moment(additional.updatedAt).format('DD MMM. YYYY') : null,
            updaterName: additional.updaterName,
            updaterInstitutionRole: additional.updaterInstitutionRole,
            canCurrentProfileRespond: additional.canCurrentProfileRespond,
            canCurrentProfileRespondForChild: additional.canCurrentProfileRespondForChild,
            isValid: true,
          };
        }
        if (respondedQuestionIds.length > 0) {
          this.respondedQuestions.push({
            institutionProfileId: instProfile.institutionProfile.id,
            questionIds: respondedQuestionIds,
          });
        }
        const profileIndex = profiles.findIndex(p => p.institutionProfile.id == instProfile.institutionProfile.id);
        if (profileIndex > -1) {
          profiles[profileIndex].additionals.concat(questions);
          if (isCheckAllShown) {
            profiles[profileIndex].isCheckAllShown = isCheckAllShown;
          }
        } else {
          if (this.isRevisionHistoryViewMode) {
            if (instProfile.institutionProfile.role == this.portalRoles.CHILD) {
              profiles.push({
                institutionProfile: instProfile.institutionProfile,
                additionals: questions,
                selectAll: null,
                isCheckAllShown: isCheckAllShown,
              });
            }
          } else {
            profiles.push({
              institutionProfile: instProfile.institutionProfile,
              additionals: questions,
              selectAll: null,
              isCheckAllShown: isCheckAllShown,
            });
          }
        }
      }
      return profiles;
    },
    checkAvailableConsents(consentList) {
      return consentList.length > 0 && consentList.filter(consent => consent.editable).length > 0;
    },
    calculateViewOrderConsent(viewOrder, currentListConsent) {
      if (currentListConsent.length == 0) {
        return 1;
      }
      const listConsent = currentListConsent
        .filter(consent => consent.viewOrder.includes(viewOrder))
        .sort((consent1, consent2) => Number(consent1.viewOrder) < Number(consent2.viewOrder));
      if (listConsent.length == 0) {
        return 1;
      }
      const newViewOrder = listConsent[0].viewOrder.split('.');
      const newOrder = newViewOrder[1] ? newViewOrder[1] : 0;
      return Number(newOrder) + 1;
    },
    filterProfileConsent() {
      const profiles = [];
      for (const institutionProfile of this.consentData) {
        if (!this.checkAvailableConsents(institutionProfile.consentResponses)) {
          continue;
        } // no show profile with empty consent
        if (institutionProfile.institutionProfile.institutionName == null) {
          institutionProfile.institutionProfile.institutionName =
            institutionProfile.institutionProfile.institution.institutionName;
        }

        if (institutionProfile.institutionProfile.name == null) {
          institutionProfile.institutionProfile.name =
            institutionProfile.institutionProfile.firstName + ' ' + institutionProfile.institutionProfile.lastName;
        }
        const profile = {
          institutionProfile: institutionProfile.institutionProfile,
          consents: {},
          selectAll: null,
          isCheckAllShown: true,
        };

        for (const consentResponse of institutionProfile.consentResponses) {
          const consent = {
            consentId: consentResponse.consentId,
            answerSharing1: null,
            answerSharing2: null,
            answerSharing3: null,
            allowedAnswers: consentResponse.allowedAnswers,
            consentResponseAnswer: consentResponse.consentResponseAnswer,
            fromDate: consentResponse.fromDate,
            viewOrder: consentResponse.viewOrder,
            toDate: consentResponse.toDate,
            consentDescription: consentResponse.consentDescription,
            isValid: true,
            editable: consentResponse.editable,
          };
          if (consentResponse.allowedAnswers.includes(consentAnswerType.NOT_AT_ALL)) {
            switch (consentResponse.consentResponseAnswer) {
              case consentAnswerType.CLASS_OR_STUE:
                consent.answerSharing1 = true;
                consent.answerSharing2 = false;
                consent.answerSharing3 = false;
                break;
              case consentAnswerType.YEAR_AND_SFO:
                consent.answerSharing1 = true;
                consent.answerSharing2 = true;
                consent.answerSharing3 = false;
                break;
              case consentAnswerType.INSTITUTION:
                consent.answerSharing1 = true;
                consent.answerSharing2 = true;
                consent.answerSharing3 = true;
                break;
              case consentAnswerType.NOT_AT_ALL:
                consent.answerSharing1 = false;
                consent.answerSharing2 = false;
                consent.answerSharing3 = false;
                break;
              default:
                consent.answerSharing1 = null;
                consent.answerSharing2 = null;
                consent.answerSharing3 = null;
                break;
            }
          }
          profile.consents[consentResponse.viewOrder] = consent;
        }
        profiles.push(profile);
      }
      return profiles;
    },
    checkedAll(profileIndex, event) {
      const value = event.target.value !== 'on' ? event.target.value : this.profiles[profileIndex].selectAll;
      if (this.type == 'consent') {
        for (const key of Object.keys(this.profiles[profileIndex].consents)) {
          if (this.profiles[profileIndex].consents[key].allowedAnswers) {
            if (!this.profiles[profileIndex].consents[key].allowedAnswers.includes(consentAnswerType.NOT_AT_ALL)) {
              // normal consent
              this.profiles[profileIndex].consents[key].consentResponseAnswer =
                value == 'yes'
                  ? this.profiles[profileIndex].consents[key].allowedAnswers[0]
                  : this.profiles[profileIndex].consents[key].allowedAnswers[1];
              if (this.profiles[profileIndex].consents[key].consentId == consentNumber.NUMBER_2) {
                // reCompute profile Consent to hide some consents relate to consent 2
                this.reComputeProfileConsentList(
                  profileIndex,
                  this.profiles[profileIndex].consents[key].consentResponseAnswer
                );
              }
            } else {
              const answer = value == 'yes' ? true : false;
              this.profiles[profileIndex].consents[key].answerSharing1 = answer;
              this.profiles[profileIndex].consents[key].answerSharing2 = answer;
              this.profiles[profileIndex].consents[key].answerSharing3 = answer;

              const currentConsent = this.profiles[profileIndex].consents[key];
              currentConsent.consentResponseAnswer =
                value == 'yes'
                  ? this.applyValidApproveAnswer(currentConsent.allowedAnswers)
                  : consentAnswerType.NOT_AT_ALL;
            }
          }
        }
      } else {
        for (const key of Object.keys(this.profiles[profileIndex].additionals)) {
          if (this.profiles[profileIndex].additionals[key].answerType == additionalDataGrossListEnum.YESNO) {
            this.profiles[profileIndex].additionals[key].yesNoResponse = value;
            if (
              this.profiles[profileIndex].additionals[key].optionalQuestionForNo != null ||
              this.profiles[profileIndex].additionals[key].optionalQuestionForYes != null
            ) {
              this.showOptionQuestionYesNo(key, profileIndex, this.profiles[profileIndex].id);
            }
          }
        }
      }
    },
    applyValidApproveAnswer(allowedAnswers) {
      // go through from highest to lowest consent
      const orderedAnswerTypes = [
        consentAnswerType.INSTITUTION,
        consentAnswerType.YEAR_AND_SFO,
        consentAnswerType.CLASS_OR_STUE,
        consentAnswerType.ACCEPTED,
        consentAnswerType.YES,
      ];

      for (let i = 0; i < orderedAnswerTypes.length; i++) {
        const answerType = orderedAnswerTypes[i];

        if (allowedAnswers.includes(answerType)) {
          return answerType;
        }
      }

      return null;
    },
    populateSelectAll() {
      // Function to populate Select All for COnsent & Additional Table, in Destop, Tablet View
      if (this.type == 'consent') {
        // populate SelectAll for Consent Tabe
        for (const profile of this.profiles) {
          let yesConsent = 0;
          let noConsent = 0;
          let checkedConsent = 0;
          for (const consentKey in profile.consents) {
            if (!profile.consents[consentKey].editable) {
              continue;
            } // no count hidden consent
            checkedConsent++;
            if (
              !profile.consents[consentKey].allowedAnswers.includes(profile.consents[consentKey].consentResponseAnswer)
            ) {
              break;
            }
            if (profile.consents[consentKey].allowedAnswers.length == 2) {
              // check normal Consent
              profile.consents[consentKey].consentResponseAnswer == profile.consents[consentKey].allowedAnswers[0]
                ? yesConsent++
                : noConsent++;
            } else {
              // check complex Consent
              if (profile.consents[consentKey].consentResponseAnswer == consentAnswerType.INSTITUTION) {
                yesConsent++;
              } else if (profile.consents[consentKey].consentResponseAnswer == consentAnswerType.NOT_AT_ALL) {
                noConsent++;
              } else {
                break;
              }
            }
          }

          if (yesConsent == checkedConsent) {
            profile.selectAll = consentAnswerType.YES;
          } else if (noConsent == checkedConsent) {
            profile.selectAll = consentAnswerType.NO;
          } else {
            profile.selectAll = null;
          }
        }
      } else {
        // populate SelectAll for Additional Table
        for (const profile of this.profiles) {
          let yesAnswer = 0;
          let noAnswer = 0;
          let chekedAnswer = 0;
          for (const additionalKey in profile.additionals) {
            if (profile.additionals[additionalKey].answerType == additionalDataGrossListEnum.YESNO) {
              chekedAnswer++;
              if (profile.additionals[additionalKey].yesNoResponse == consentAnswerType.YES) {
                yesAnswer++;
              } else if (profile.additionals[additionalKey].yesNoResponse == consentAnswerType.NO) {
                noAnswer++;
              } else {
                break;
              }
            } else {
              continue;
            }
          }

          if (yesAnswer == chekedAnswer) {
            profile.selectAll = consentAnswerType.YES;
          } else if (noAnswer == chekedAnswer) {
            profile.selectAll = consentAnswerType.NO;
          } else {
            profile.selectAll = null;
          }
        }
      }
    },
    changeConsentNo2(e, profileIndex) {
      let answer = null;
      if (e.target.value == 'on') {
        // element io value
        answer = e.target.checked ? consentAnswerType.ACCEPTED : consentAnswerType.DECLINED;
      } else {
        answer = e.target.value;
      }
      this.reComputeProfileConsentList(profileIndex, answer);
    },
    reComputeProfileConsentList(profileIndex, consentResponseAnswer) {
      // this method is using for compute editable field in consent of each profile,
      // when user declined consent 2, we have to hide consent No 3,11,26,32
      // we use it to show or hide these consents in single profile table
      const checkList = [3, 11, 26, 32];
      for (const consent in this.profiles[profileIndex].consents) {
        if (checkList.includes(this.profiles[profileIndex].consents[consent].consentId)) {
          if (consentResponseAnswer == consentAnswerType.DECLINED) {
            this.profiles[profileIndex].consents[consent].editable = false;
            if (this.profiles[profileIndex].consents[consent].allowedAnswers.length == 2) {
              this.profiles[profileIndex].consents[consent].consentResponseAnswer = consentAnswerType.DECLINED;
            } else {
              // case complex answer
              this.profiles[profileIndex].consents[consent].consentResponseAnswer = consentAnswerType.NOT_AT_ALL;
              this.profiles[profileIndex].consents[consent].answerSharing1 = false;
              this.profiles[profileIndex].consents[consent].answerSharing2 = false;
              this.profiles[profileIndex].consents[consent].answerSharing3 = false;
            }
          } else {
            this.profiles[profileIndex].consents[consent].editable = true;
          }
        }
      }
      this.reComputeConsentList();
    },
    reComputeConsentList() {
      // this method is using for re-compute editable field in consent
      // we use it to show or hide consents in multiple profile table
      for (const consent of this.consents) {
        let availableConsent = 0;
        for (const profile of this.profiles) {
          for (const item in profile.consents) {
            if (item == consent.viewOrder) {
              availableConsent += profile.consents[item].editable ? 1 : 0;
            }
          }
        }
        consent.editable = availableConsent > 0;
      }
    },
    filterQuestion() {
      this.questions = []; // reset question
      if (this.type == 'consent') {
        const consents = [];
        if (this.consentData.length > 0) {
          for (const institutionProfile of this.consentData) {
            // sort consent to set viewOrder for consent No36
            institutionProfile.consentResponses.sort(
              (a, b) =>
                Number(a.viewOrder) < Number(b.viewOrder) ||
                moment(a.fromDate) < moment(b.fromDate) ||
                moment(a.toDate) < moment(b.toDate)
            );
            for (const consentResponse of institutionProfile.consentResponses) {
              if (consentResponse.consentId == consentNumber.NUMBER_36) {
                consentResponse.viewOrder = '10.' + this.calculateViewOrderConsent('10', consents);
              } else if (consentResponse.consentId == consentNumber.NUMBER_27) {
                consentResponse.viewOrder = '9.' + this.calculateViewOrderConsent('9', consents);
              }
              consentResponse.viewOrder = consentResponse.viewOrder.toString();
              consentResponse.consentResponseStatus == consentResponseStatus.PENDING
                ? (consentResponse.consentResponseAnswer = null)
                : '';
              const existConsentIndex = consents.findIndex(consent => consent.viewOrder == consentResponse.viewOrder);
              if (existConsentIndex == -1) {
                consents.push(consentResponse);
              } else {
                consents[existConsentIndex].editable = consents[existConsentIndex].editable || consentResponse.editable;
              }
            }
          }
          consents.sort((a, b) => parseFloat(a.viewOrder) - parseFloat(b.viewOrder));
        }
        this.consents = consents;
      } else {
        for (const additionals of this.additionalData) {
          for (const question of additionals.additionalDataResponses) {
            if (this.questions.find(q => q.additionalDataId == question.additionalDataId) == null) {
              question.showOptionQuestionYes = false;
              question.showOptionQuestionNo = false;
              question.instProfileIndex = null;
              question.responding = null;
              this.questions.push(question);
            }
          }
        }
        this.questions.sort((a, b) => a.createdAt > b.createdAt);
      }
    },
    submitAdditionalTableOnBehalf() {
      const additionalData = this.submitAdditionalTable();
      return {
        institutionProfileId: additionalData[0] != null ? additionalData[0].institutionProfileId : null,
        additionalDataResponses: additionalData[0] != null ? additionalData[0].additionalDataResponses : null,
      };
    },
    submitAdditionalTable() {
      this.isSubmitted = true;
      const additionalData = [];
      for (const profile of this.profiles) {
        const answer = {};
        answer.institutionProfileId = profile.institutionProfile.id;
        answer.additionalDataResponses = [];
        let answerQuestion = null;
        for (const key of Object.keys(profile.additionals)) {
          let yesnoResponse = null;
          let getValue = true;
          if (!profile.additionals[key].canCurrentProfileRespond) {
            getValue = false;
          }
          if (getValue) {
            if (profile.additionals[key].answerType == additionalDataGrossListEnum.YESNO) {
              if (profile.additionals[key].yesNoResponse == 'yes') {
                yesnoResponse = true;
                answerQuestion =
                  profile.additionals[key].optionalQuestionForYes == null ? null : profile.additionals[key].responding;
              } else {
                yesnoResponse = false;
                answerQuestion =
                  profile.additionals[key].optionalQuestionForNo == null ? null : profile.additionals[key].responding;
              }
            } else {
              answerQuestion = profile.additionals[key].response;
            }
            answer.additionalDataResponses.push({
              additionalDataId: profile.additionals[key].additionalDataId,
              additionalDataResponseId: profile.additionals[key].additionalDataResponseId,
              response: answerQuestion,
              yesNoResponse: yesnoResponse,
            });
          }
        }
        additionalData.push(answer);
      }
      return additionalData;
    },
    showOptionQuestionYesNo(additionalDataId, instProfileIndex, instProfileId) {
      const value = this.profiles[instProfileIndex].additionals[additionalDataId].yesNoResponse;
      const questionIndex = this.questions.findIndex(q => q.additionalDataId == additionalDataId);
      // check all institution profiles
      const allCheckTheSame =
        this.profiles.filter(
          p =>
            p.institutionProfile.id != instProfileId &&
            p.additionals[additionalDataId] != undefined &&
            p.additionals[additionalDataId].yesNoResponse != null &&
            p.additionals[additionalDataId].yesNoResponse != value
        ).length == 0;

      if (this.questions[questionIndex].response != null) {
        this.questions[questionIndex].responding = this.questions[questionIndex].response;
      }
      if (value == 'yes') {
        this.profiles[instProfileIndex].additionals[additionalDataId].showOptionQuestionNo = false;
        if (this.profiles[instProfileIndex].additionals[additionalDataId].optionalQuestionForYes != null) {
          this.questions[questionIndex].showOptionQuestionYes = true;
          this.profiles[instProfileIndex].additionals[additionalDataId].showOptionQuestionYes = true;
        }
        if (allCheckTheSame == true) {
          this.questions[questionIndex].showOptionQuestionNo = false;
        }
      } else {
        this.profiles[instProfileIndex].additionals[additionalDataId].showOptionQuestionYes = false;
        if (this.profiles[instProfileIndex].additionals[additionalDataId].optionalQuestionForNo != null) {
          this.questions[questionIndex].showOptionQuestionNo = true;
          this.profiles[instProfileIndex].additionals[additionalDataId].showOptionQuestionNo = true;
        }

        if (allCheckTheSame == true) {
          this.questions[questionIndex].showOptionQuestionYes = false;
        }
      }
      this.profiles[instProfileIndex].additionals[additionalDataId].responding =
        this.profiles[instProfileIndex].additionals[additionalDataId].response;
    },
    validateConsentAnswered(isSubmitted = false) {
      if (isSubmitted) {
        this.isSubmitted = isSubmitted;
      }
      let result = true;
      for (const profile of this.profiles) {
        for (const key of Object.keys(profile.consents)) {
          if (profile.consents[key].allowedAnswers) {
            if (
              profile.consents[key].allowedAnswers.find(
                answer => answer == profile.consents[key].consentResponseAnswer
              ) == null
            ) {
              profile.consents[key].isValid = false;
              result = false;
            } else {
              profile.consents[key].isValid = true;
            }
          }
        }
      }
      return result;
    },
    validateAdditionalAnswered(isSubmitted = false) {
      if (!isSubmitted) {
        return false;
      }
      this.isSubmitted = isSubmitted;
      for (const profile of this.profiles) {
        for (const key of Object.keys(profile.additionals)) {
          let needValidation = true;
          if (!profile.additionals[key].canCurrentProfileRespond) {
            needValidation = false;
          }

          if (needValidation) {
            const isMissingAnswerYesNo =
              profile.additionals[key].answerType === additionalDataGrossListEnum.YESNO &&
              !profile.additionals[key].yesNoResponse;
            const isMissingAnswerText =
              profile.additionals[key].answerType === additionalDataGrossListEnum.TEXT &&
              !profile.additionals[key].response;
            const isMissingAnswerPhoneNumber =
              profile.additionals[key].answerType === additionalDataGrossListEnum.PHONENUMBER &&
              !profile.additionals[key].response;

            // AULA-34799:
            // Disable null check on questions which canCurrentProfileRespondForChild is true for employee.
            const isRequiredResponseForChild =
              !profile.additionals[key].canCurrentProfileRespondForChild ||
              this.loggedInProfile.role !== portalRoles.EMPLOYEE ||
              this.$route.name === 'stepOnboarding';

            if (
              (isMissingAnswerYesNo || isMissingAnswerText || isMissingAnswerPhoneNumber) &&
              isRequiredResponseForChild
            ) {
              return false;
            }

            // Validate phone number
            if (
              profile.additionals[key].answerType === additionalDataGrossListEnum.PHONENUMBER &&
              !this.$options.filters.isPhoneNumber(profile.additionals[key].response)
            ) {
              return false;
            }
          }
        }
      }
      return true;
    },
    submitConsentResponse() {
      const consentData = [];
      for (const profile of this.profiles) {
        if (profile.institutionProfile.id != null) {
          consentData.push(this.createConsentAnswerForProfile(profile));
        }
      }
      return { institutionProfilesConsentAnswers: consentData };
    },
    createConsentAnswerForProfile: function (profile) {
      const consent = {};
      consent.institutionProfileId = profile.institutionProfile.id;
      consent.institutionProfileConsentUpdates = [];
      for (const key of Object.keys(profile.consents)) {
        if (this.consentHasBeenAnswered(profile.consents[key])) {
          consent.institutionProfileConsentUpdates.push(this.createConsentUpdate(profile.consents[key]));
        }
      }
      return consent;
    },
    consentHasBeenAnswered: function (consent) {
      return consent.consentResponseAnswer !== null;
    },
    createConsentUpdate: function (consent) {
      return {
        consentId: consent.consentId,
        answer: consent.consentResponseAnswer,
        fromDate: this.consentForLeavingInstitution.includes(consent.consentId) ? consent.fromDate || null : null,
        toDate: this.consentForLeavingInstitution.includes(consent.consentId) ? consent.toDate || null : null,
      };
    },
    responseComplexConsent(e, consentViewOrder, profileIndex, questionNo) {
      let answer = null;
      if (e.target.value == 'on') {
        // element io value
        answer = e.target.checked;
      } else {
        answer = e.target.value;
      }
      answer = JSON.parse(answer);
      if (questionNo == consentNumber.NUMBER_3) {
        if (answer) {
          this.profiles[profileIndex].consents[consentViewOrder].answerSharing2 = true;
          this.profiles[profileIndex].consents[consentViewOrder].answerSharing1 = true;
          this.profiles[profileIndex].consents[consentViewOrder].consentResponseAnswer = consentAnswerType.INSTITUTION;
        } else {
          if (
            this.profiles[profileIndex].consents[consentViewOrder].answerSharing2 &&
            this.profiles[profileIndex].consents[consentViewOrder].allowedAnswers.includes(
              consentAnswerType.YEAR_AND_SFO
            )
          ) {
            this.profiles[profileIndex].consents[consentViewOrder].consentResponseAnswer =
              consentAnswerType.YEAR_AND_SFO;
          } else if (
            this.profiles[profileIndex].consents[consentViewOrder].answerSharing1 &&
            this.profiles[profileIndex].consents[consentViewOrder].allowedAnswers.includes(
              consentAnswerType.CLASS_OR_STUE
            )
          ) {
            this.profiles[profileIndex].consents[consentViewOrder].consentResponseAnswer =
              consentAnswerType.CLASS_OR_STUE;
          } else if (
            this.profiles[profileIndex].consents[consentViewOrder].answerSharing1 &&
            this.profiles[profileIndex].consents[consentViewOrder].allowedAnswers.includes(consentAnswerType.NOT_AT_ALL)
          ) {
            this.profiles[profileIndex].consents[consentViewOrder].consentResponseAnswer = consentAnswerType.NOT_AT_ALL;
          }
        }
      } else if (questionNo == consentNumber.NUMBER_2) {
        if (!answer) {
          this.profiles[profileIndex].consents[consentViewOrder].answerSharing3 = false;
          if (
            this.profiles[profileIndex].consents[consentViewOrder].answerSharing1 &&
            this.profiles[profileIndex].consents[consentViewOrder].allowedAnswers.includes(
              consentAnswerType.CLASS_OR_STUE
            )
          ) {
            this.profiles[profileIndex].consents[consentViewOrder].consentResponseAnswer =
              consentAnswerType.CLASS_OR_STUE;
          } else {
            this.profiles[profileIndex].consents[consentViewOrder].consentResponseAnswer = consentAnswerType.NOT_AT_ALL;
          }
        } else if (answer) {
          this.profiles[profileIndex].consents[consentViewOrder].answerSharing1 = true;
          this.profiles[profileIndex].consents[consentViewOrder].consentResponseAnswer = consentAnswerType.YEAR_AND_SFO;
        }
      } else {
        if (!answer) {
          this.profiles[profileIndex].consents[consentViewOrder].answerSharing3 = false;
          this.profiles[profileIndex].consents[consentViewOrder].answerSharing2 = false;
          this.profiles[profileIndex].consents[consentViewOrder].consentResponseAnswer = consentAnswerType.NOT_AT_ALL;
        } else {
          this.profiles[profileIndex].consents[consentViewOrder].consentResponseAnswer =
            consentAnswerType.CLASS_OR_STUE;
        }
      }
    },
    checkEditableConsent() {
      // Set editable value for consent in some special cases
      for (const [profileIndex, profile] of this.profiles.entries()) {
        if (profile.consents['1'] && profile.consents['1'].consentResponseAnswer == consentAnswerType.DECLINED) {
          // COnsentId 2 answer was Declined
          this.reComputeProfileConsentList(profileIndex, consentAnswerType.DECLINED);
        }
      }
    },
    initConsent() {
      this.consentData = cloneDeep(this.data);
      this.filterQuestion();
      this.profiles = this.filterProfileConsent();
      this.checkEditableConsent();
      this.populateSelectAll();
      this.originalDataProfiles = cloneDeep(this.profiles);
    },
    initAdditionalData() {
      this.additionalData = cloneDeep(this.data);
      this.filterQuestion();
      this.profiles = this.filterProfiles();
      this.originalDataProfiles = cloneDeep(this.profiles);
      if (!this.isRevisionHistoryViewMode && this.respondedQuestions.length > 0) {
        for (const responded of this.respondedQuestions) {
          const instProfileId = responded.institutionProfileId;
          for (let i = 0; i < responded.questionIds.length; i++) {
            const profileIndex = this.profiles.findIndex(p => p.institutionProfile.id == instProfileId);
            this.showOptionQuestionYesNo(responded.questionIds[i], profileIndex, instProfileId);
          }
        }
      }
      this.originalDataProfiles = cloneDeep(this.profiles);
    },
  },
  components: {
    AulaButton,
    AulaButtons,
    'additional-answer': AdditionalAnswer,
    'consent-answer': ConsentAnswer,
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/scss/core/variables';
@import '../../assets/scss/core/breakpoints';
@import '../../assets/scss/elements/consentAdditional';

.consent-table .consent-actions {
  display: flex;
  justify-content: flex-end;
  gap: 25px;
  margin-bottom: 20px;
}
.functions {
  margin-top: 25px;

  /deep/ .custom-radio {
    label span {
      position: absolute;
      top: -24px;
      left: -48px;
      white-space: nowrap;
      display: inline-block;
    }

    &:first-child {
      margin-right: 42px;

      @include breakpoint-lg-down {
        margin-left: 10px;
      }
    }
  }
}

.function.mobile {
  margin-right: 0px;
}
</style>
