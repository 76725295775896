<template>
  <div class="user-data-table mt-3">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="div" role="tab" @click="unfoldTable">
        {{ headerText | fromTextKey }}
        <div class="pull-right">
          <b-btn v-if="showTable && type != null" size="sm" class="margin-right" variant="link" @click="exportTable()">
            <i class="icon-Aula_export" /> Eksporter
          </b-btn>
          <b-btn
            v-if="showTable && type != null && !isDisabledDelete"
            size="sm"
            class="margin-right"
            variant="link"
            @click="deleteAll()"
          >
            <i class="icon-Aula_bin" /> Slet alt
          </b-btn>
          <i :class="showTable ? 'icon-Aula_up-arrow' : 'icon-Aula_down-arrow'" />
        </div>
      </b-card-header>
      <b-collapse :id="tableRef" v-model="showTable" :accordion="tableRef" role="tabpanel">
        <b-card-body v-if="showTable">
          <b-row class="mb-2">
            <b-col v-if="showDateSearch" cols="4">
              {{ 'TABLE_DATE_FILTER_START' | fromTextKey }}
              <el-date-picker
                id="startDate"
                v-model="startDate"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                type="date"
                :editable="false"
                :placeholder="'MESSAGE_PLACEHOLDER_SELECT_DATE' | fromTextKey"
                :picker-options="{ firstDayOfWeek: isoWeekdayEnum.MONDAY }"
                @focus="datePickerModifier()"
                @change="validateDates()"
              />
              <b-alert variant="danger" :show="showDateWarning">
                {{ dateWarningText }}
              </b-alert>
            </b-col>
            <b-col v-if="showDateSearch" cols="4">
              {{ 'TABLE_DATE_FILTER_END' | fromTextKey }}
              <el-date-picker
                id="endDate"
                v-model="endDate"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                type="date"
                :editable="false"
                :placeholder="'MESSAGE_PLACEHOLDER_SELECT_DATE' | fromTextKey"
                :picker-options="{ firstDayOfWeek: isoWeekdayEnum.MONDAY }"
                @focus="datePickerModifier()"
                @change="validateDates()"
              />
            </b-col>
          </b-row>
          <aula-table
            :ref="tableRef"
            :per-page="perPage"
            :items="tableItems"
            :fields="fields"
            :responsive="true"
            :table-ref="tableRef"
            :server-side="true"
            :server-side-url="url"
            :is-http-post="false"
            :prepare-params="prepareParamsForTable"
            :prepare-items="prepareItemsForTable"
            empty-table-text="USERS_WARNING_ZERO_RESULTS_FROM_SEARCH"
            empty-table-filtered-text="USERS_WARNING_ZERO_RESULTS_FROM_SEARCH"
            :show-pagination="true"
            @onMediaDetailClicked="onMediaDetailClicked"
            @deleteUserData="deleteRow"
          />
        </b-card-body>
      </b-collapse>
    </b-card>
    <aula-modal ref="cannotRemoveUserData" :show-cancel="false" @okClicked="$refs.cannotRemoveUserData.hide()">
      <p>{{ 'USERS_WARNING_CANNOT_DELETE_SOME_USER_DATA' | fromTextKey }}</p>
    </aula-modal>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import { types } from '../../store/types/types';
import moment from 'moment-timezone';
import * as axios from 'axios';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { isValid, isAfter } from '../../../shared/utils/dateUtil';
import { isoWeekdayEnum } from '../../../shared/enums/isoWeekdayEnum';
import { DateTimeUtil } from '../../../shared/utils/dateTimeUtil';

export default {
  props: {
    selectedUser: Object,
    type: { type: String, default: null },
    isDisabledDelete: { type: Boolean, default: false },
    showDateSearch: { type: Boolean, default: true },
    headerText: { type: String, default: '' },
    parent: { type: String, default: '' },
    tableRef: { type: String, default: '' },
    url: { type: String, default: '' },
    perPage: { type: Number, default: 20 },
    showCategory: { type: Boolean, default: false },
    showSensitivity: { type: Boolean, default: false },
    showCreator: { type: Boolean, default: true },
    prepareParams: Function,
    prepareItems: Function,
  },
  data() {
    return {
      parentTypes: parentTypes,
      moment: moment,
      allUserDataSelected: false,
      showDateWarning: false,
      showTable: false,
      tableItems: [],
      response: [],
      startDate: moment().subtract(1, 'years').format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
      endDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
      fields: {
        creationDate: {
          label: this.$options.filters.fromTextKey('USERS_FIELDS_CREATION_DATE'),
        },
        ...(this.parent == parentTypes.CALENDAR && {
          eventDate: {
            label: this.$options.filters.fromTextKey('USERS_FIELDS_START_END'),
          },
        }),
        category: {
          label: this.$options.filters.fromTextKey('USERS_FIELDS_CATEGORY'),
        },
        title: {
          label: this.$options.filters.fromTextKey('USERS_FIELDS_TITLE'),
        },
        description: {
          label: this.$options.filters.fromTextKey('USERS_FIELDS_DESCRIPTION'),
          type: 'html',
        },
        contactPerson: {
          label: this.$options.filters.fromTextKey('USERS_FIELDS_CONTACT_PERSON'),
        },
        sensitivity: {
          label: this.$options.filters.fromTextKey('USERS_FIELDS_SENSITIVITY'),
        },
        actions: {
          label: this.$options.filters.fromTextKey('USERS_FIELDS_ACTIONS'),
          type: 'action',
        },
      },
      isLoading: false,
    };
  },
  computed: {
    isoWeekdayEnum() {
      return isoWeekdayEnum;
    },
    isStartDateAfterEndDate() {
      return isAfter(this.startDate, this.endDate);
    },
    isValidDates() {
      return isValid(this.startDate) && isValid(this.endDate);
    },
    dateWarningText() {
      if (this.isStartDateAfterEndDate) {
        return this.$options.filters.fromTextKey('ALL_USER_DATA_START_DATE_IS_AFTER_END_DATE');
      } else if (!this.isValidDates) {
        return this.$options.filters.fromTextKey('ALL_USER_DATA_DATE_IS_INVALID');
      }
      return '';
    },
  },
  methods: {
    unfoldTable() {
      if (!this.isLoading) {
        this.showTable = !this.showTable;
      }
    },
    exportTable() {
      this.isLoading = true;
      const currentDateTime = DateTimeUtil.getCurrentDateTime();
      const startTime = DateTimeUtil.getStartOf(this.startDate, 'day');
      let endTime = DateTimeUtil.getEndOf(this.endDate, 'day');
      if (DateTimeUtil.isSameOrAfter(endTime, currentDateTime)) {
        endTime = currentDateTime;
      }
      this.handleEntitiesRTBF({
        instProfileId: this.selectedUser.id,
        type: this.type,
        operationType: 'Export',
        start: startTime,
        end: endTime,
      }).then(() => {
        this.showTable = true;
        this.isLoading = false;
        this.$refs[this.tableRef].refresh();
      });
    },
    deleteAll() {
      this.isLoading = true;
      const currentDateTime = DateTimeUtil.getCurrentDateTime();
      const startTime = DateTimeUtil.getStartOf(this.startDate, 'day');
      let endTime = DateTimeUtil.getEndOf(this.endDate, 'day');
      if (DateTimeUtil.isSameOrAfter(endTime, currentDateTime)) {
        endTime = currentDateTime;
      }
      this.handleEntitiesRTBF({
        instProfileId: this.selectedUser.id,
        type: this.type,
        operationType: 'Delete',
        start: startTime,
        end: endTime,
      }).then(() => {
        this.showTable = true;
        this.isLoading = false;
        this.$refs[this.tableRef].refresh();
      });
    },
    validateDates() {
      this.showDateWarning = this.isStartDateAfterEndDate || !this.isValidDates;
      if (!this.showDateWarning) {
        this.$refs[this.tableRef].refresh();
      }
    },
    prepareParamsForTable(ctx, fields) {
      const startTime = moment(this.startDate).hours(0).minutes(0).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
      const endTime = moment(this.endDate).hours(23).minutes(59).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
      return this.prepareParams(ctx, fields, startTime, endTime);
    },
    prepareItemsForTable(response) {
      this.response = response.data.data;
      this.tableItems = this.prepareItems(response);
      return this.tableItems;
    },
    deleteRow(row) {
      this.deleteUserData({
        allItems: this.tableItems,
        selectedIds: [row.item.itemId],
        selectedUser: this.selectedUser,
      }).then(result => {
        if (result.promises.length) {
          axios.all(result.promises).then(() => {
            this.showSuccessToastr('USERS_TOASTR_DELETE_DATA');
            this.$refs[this.tableRef].refresh();
          });
        }
        if (result.errorMsg.length) {
          this.$refs.cannotRemoveUserData.show();
        }
      });
    },
    onMediaDetailClicked(params) {
      this.$emit("openMediaDetail", params.item.object.id);
    },
    ...mapActions({
      deleteUserData: types.DELETE_USER_DATA,
      handleEntitiesRTBF: types.HANDLE_ENTITIES_RTBF,
    }),
    ...mapMutations({
      showSuccessToastr: types.MUTATE_SUCCESS_TEXT,
    }),
  },
  mounted() {
    if (!this.showCategory) {
      delete this.fields.category;
    }
    if (!this.showSensitivity) {
      delete this.fields.sensitivity;
    }
    if (!this.showCreator) {
      delete this.fields.contactPerson;
    }
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
/deep/ .aula-table {
  thead th:last-child {
    padding-right: 50px;
  }
}

.select-all-checkbox {
  position: absolute;
  right: 25px;
  top: 90px;
  z-index: 1050;
}

.icon-Aula_down-arrow,
.icon-Aula_up-arrow {
  font-size: 10px;
}
</style>
