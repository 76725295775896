<template>
  <div class="aula-search-outer">
    <div class="aula-search">
      <i v-if="showSearchIcon" class="icon-Aula_search" />
      <aula-select
        :id="`aulaSearchCustom-` + uniqueId"
        ref="aulaSearchCustom"
        v-model="selectedUsersAndGroups"
        :multiple="multiple"
        :multiple-limit="multipleLimit"
        :filterable="remoteSearch"
        :class="cssClass"
        :remote="remoteSearch"
        :disabled="disabled"
        :popper-class="popperClass"
        reserve-keyword
        :placeholder="placeholderTextkey | fromTextKey"
        :aria-label="placeholderTextkey | fromTextKey"
        :remote-method="loadResults"
        :loading="loading"
        :allow-create="allowCreate"
        :popper-append-to-body="appendToBody"
        value-key="value"
        :title="hoverText | fromTextKey"
        @change="emitSelected"
        @focus="emitFocus"
        @keydown.down.native="expandGroupPortalRolesOnKeyDown()"
        @remove-tag="emitRemoveTag($event)"
      >
        <template v-for="(group, i) in storedResults">
          <template v-if="group.options.length > 0">
            <el-option-group :key="i" :data-type="group.value" :data-values="group.options.map(option => option.value)">
              <li class="el-select-group__title">
                <b-form-checkbox
                  v-if="group.canSelect"
                  class="mb-0"
                  :checked="checkSelectAllGroupOptions(group)"
                  @change="selectAllGroupOptions($event, group)"
                >
                  {{ group.label.toUpperCase() }}
                </b-form-checkbox>
                <template v-else>
                  {{ group.label }}
                </template>
              </li>
            </el-option-group>
            <template v-for="(item, ii) in group.options">
              <aula-option
                v-show="!item.isHidden"
                :key="`${i}-${item.id}-${ii}`"
                :disabled="(multipleLimit > 0 && selectedUsersAndGroups.length == multipleLimit) || item.disabled"
                :data-type="item.type"
                :data-portalrole="item.portalRole"
                :data-parent="item.class != null && item.class.includes('parent')"
                :data-id="item.id"
                :data-selected="item.isSelected"
                :data-groupid="item.groupId"
                :value="item"
                :label="item.labelWhenGroupIsChosen ? item.labelWhenGroupIsChosen : item.label"
                :class="[
                  {
                    'with-checkbox': showCheckbox,
                    'is-hidden': item.isHidden,
                    selected: getSelectedStatusForProfile(item),
                    'is-updating-selection': isSearchComponentLoading,
                  },
                  item.class,
                  item.type,
                ]"
              >
                <slot name="optionContent" v-bind="item">
                  <i
                    v-if="getIsOptionContentVisible(item)"
                    v-prevent-double-click
                    :class="item.icon"
                    @click.stop="handleIconClick(item)"
                  />
                  <KeywordHighlight v-if="remoteSearch && item.label != null" :queries="queryString">
                    {{ item.label }}
                  </KeywordHighlight>
                </slot>
              </aula-option>
            </template>
          </template>
        </template>
      </aula-select>
      <AulaButton
        v-if="canShowClearButton"
        :aria-label="$t('button.clear_all')"
        :title="$t('button.clear_all')"
        class="button-close"
        variant="link"
        @click="handleClearClick"
      >
        <IconContainer>
          <Icon :name="iconClassEnum.CLOSE" />
        </IconContainer>
      </AulaButton>
    </div>
    <AulaModal ref="warningNoMembersModal" :show-cancel="false" @okClicked="$refs.warningNoMembersModal.hide()">
      {{ 'SEARCH_NO_MEMBERS_IN_GROUP' | fromTextKey }}
    </AulaModal>
    <AulaModal
      ref="clearSelectedUsersAndGroupsWarningModal"
      :ok-text="'BUTTON_YES'"
      @okClicked="clearSelectedUsersAndGroups"
      @cancelClicked="$refs.clearSelectedUsersAndGroupsWarningModal.hide()"
    >
      <slot name="reset-warning">
        {{ $t('warning.clear_everything_in_the_field') }}
        <br />
        {{ $t('warning.want_to_continue') }}
      </slot>
    </AulaModal>
  </div>
</template>

<script>
import { types } from '../../src_adm/store/types/types';
import { mapActions, mapGetters } from 'vuex';
import { docTypes } from '../enums/docTypes';
import { moduleTypes } from '../enums/moduleTypes';
import { portalRoles } from '../enums/portalRoles';
import Vue from 'vue';
import $ from 'jquery';
import uniqueId from 'lodash/uniqueId';
import cloneDeep from 'lodash/cloneDeep';

import { messageOwnerTypes } from '../enums/messageOwnerTypes';
import { userTypes } from '../enums/userTypes.enum';
import { recipientGroupTypes } from '../enums/recipientGroupTypes';
import preventDoubleClick from '../directives/PreventDoubleClick/PreventDoubleClick';
import KeywordHighlight from 'vue-text-highlight';
import AulaButton from './AulaButton.vue';
import Icon from './Icon.vue';
import { iconClassEnum } from '../enums/iconClassEnum';
import IconContainer from './IconContainer.vue';

export default {
  directives: {
    preventDoubleClick,
  },
  props: {
    includeOtpFromGuardianInSubGroup: { type: Boolean, default: false },
    searchResultLoader: { type: Function, required: false },
    results: { type: Array, required: true, default: () => [] },
    multiple: { type: Boolean, default: true },
    multipleLimit: { type: Number, default: 0 },
    clearable: { type: Boolean, default: false },
    highlight: { type: Boolean, default: true },
    showSearchIcon: { type: Boolean, default: false },
    placeholderTextkey: { type: String, default: 'SEARCH_LABEL_CHILD' },
    splitGroupsParticipants: { type: Boolean, default: true },
    disableSubGroupSelect: { type: Boolean, default: false },
    enableRelativesForStudents: { type: Boolean, default: false },
    resetSearchInput: { type: Boolean, default: false },
    resetSelected: { type: Boolean, default: false },
    instanceToReset: { type: [String, Number], default: null },
    disabled: { type: Boolean, default: false },
    allowCreate: { type: Boolean, default: false },
    inviteGroupWhenAddingMembers: { type: Boolean, default: false },
    filterPortalRolesWhenAddingMembers: { type: Array, default: () => [] },
    instance: { type: Number, default: null },
    existingParticipants: { type: Array, default: () => [] },
    addExistingParticipants: { type: Boolean, default: true },
    cssClass: { type: String, default: '' },
    setFocus: { type: Boolean, default: false },
    includeSelf: { type: Boolean, default: false },
    remoteSearch: { type: Boolean, default: true },
    closeDropdownAfterSelect: { type: Boolean, default: true },
    closeSearchResult: { type: Boolean, default: false },
    inviteMembersWhenSelectingGroup: { type: Boolean, default: false },
    inviteMembersWhenSelectingGroupPortalRole: {
      type: Boolean,
      default: false,
    },
    disablePortalRolesMembersWhenExpandingGroup: {
      type: Array,
      default: () => [],
    },
    onlyUniqueProfiles: { type: Boolean, default: false },
    showMembersFromGroup: { type: Boolean, default: false },
    showCheckbox: { type: Boolean, default: true },
    fromModule: { type: String, default: null },
    popperClass: { type: String, default: 'aula-search-popper' },
    selectedProfileIdsForCheckbox: { type: Array, default: () => [] },
    hoverText: { type: String, default: '' },
    activeInstitutionCodeForGroupMemberships: { type: String, default: '' },
    appendToBody: { type: Boolean, default: true },
  },
  data: function () {
    return {
      selectedUsersAndGroups: [],
      allSelectedMembers: [],
      storedResults: [],
      showingChildRelations: false,
      loading: false,
      queryString: '',
      selectedBeforeChange: [],
      uniqueId: uniqueId('aula-search-dropdown-'),
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      institutions: types.GET_INSTITUTIONS,
      groupMemberships: types.GET_GROUP_MEMBERSHIPS_LIGHT,
      calendarActiveInstProfileId: types.GET_CALENDAR_ACTIVE_INST_PROFILE_ID,
      isSearchComponentLoading: types.IS_SEARCH_COMPONENT_LOADING,
    }),
    groupOptions() {
      return this.storedResults.find(result => result.value === recipientGroupTypes.GROUPS)?.options || [];
    },
    canShowClearButton() {
      return this.clearable && this.selectedUsersAndGroups.length > 0 && !this.disabled;
    },
  },
  methods: {
    ...mapActions({
      loadGroupMemberships: types.LOAD_GROUP_MEMBERSHIPS_LIGHT,
    }),
    handleClearClick() {
      this.$refs.clearSelectedUsersAndGroupsWarningModal.show();
    },
    clearSelectedUsersAndGroups() {
      this.emitSelected([]);
      this.$refs.clearSelectedUsersAndGroupsWarningModal.hide();
    },
    getIsOptionContentVisible(item) {
      if (!(this.remoteSearch && item.icon && item.icon !== '')) {
        return false;
      }
      if (item.type === 'profile') {
        return true;
      }
      return Boolean(item.isAllowMembersToBeShown || item.class?.includes('parent'));
    },
    getSelectedStatusForProfile(profile) {
      if (this.multiple) {
        const hasSelectedFromGroup =
          profile.type === userTypes.PROFILE &&
          this.selectedUsersAndGroups.some(
            item =>
              item.type === userTypes.GROUP && item.id === profile.groupId && item.portalRole === profile.portalRole
          );
        return (
          profile.isSelected || hasSelectedFromGroup || this.selectedProfileIdsForCheckbox.some(id => id == profile.id)
        );
      } else {
        return profile.isSelected || this.selectedProfileIdsForCheckbox.some(id => id == profile.id);
      }
    },
    async handleIconClick(item) {
      if (this.isSearchComponentLoading) {
        return;
      }
      // What should happen when the user click on the row icon
      if (item.type == 'group') {
        if (item.portalRole != null) {
          // Clicking on subgroup icon: Show/hide all members of the subgroup
          const index = this.findWithAttr(
            this.storedResults.find(res => res.value == 'groups').options,
            'id',
            item.id,
            'portalRole',
            item.portalRole
          );
          item.isExpanded = !item.isExpanded;
          if (!item.isExpanded) {
            this.hideProfileDropdownItems(item);
          } else {
            await this.loadGroupMemberships({
              groupId: item.id,
              portalRoles: this.getPortalRolesFromSubGroup(item),
              isSearchComponentLoading: true,
              activeInstitutionCode: this.activeInstitutionCodeForGroupMemberships,
            });
            this.expandGroupMembers(index, item.portalRole, item.id);
          }
          if (item.isExpanded) {
            item.icon = 'el-select__caret el-input__icon el-icon-arrow-up';
          } else {
            item.icon = 'el-select__caret el-input__icon el-icon-arrow-down';
          }
        } else if (item.portalRole == null) {
          // Clicking on main group icon: Show/hide the subgroups
          for (const group of this.storedResults.find(res => res.value === 'groups').options) {
            if (group.id != item.id) {
              continue;
            }

            if (group.portalRole === 'members') {
              group.isHidden = !group.isHidden;
              continue;
            }
            if (group.portalRole != null && group.type == 'group') {
              group.isHidden = !group.isHidden;
              if (group.isHidden) {
                group.icon = 'el-select__caret el-input__icon el-icon-arrow-down';
                this.hideProfileDropdownItems(group);
              }
            } else if (group.portalRole == null && group.type == 'group') {
              group.isExpanded = !group.isExpanded;
              if (group.isExpanded) {
                group.icon = 'el-select__caret el-input__icon el-icon-arrow-up';
              } else {
                group.icon = 'el-select__caret el-input__icon el-icon-arrow-down';
              }
            }
          }
        }
      } else if (item.type == 'profile') {
        // Clicking on child icon: Show relatives of the child
        if (item.portalRole == portalRoles.CHILD) {
          if (!this.showingChildRelations) {
            this.showingChildRelations = true;
            this.loadChildRelations(item.id);
          } else {
            this.showingChildRelations = false;
            this.loadResults(this.queryString);
          }
        }
      }

      // TODO: Get rid of the misuse of applyLabel label names should be resolved by the library, so we don't have to guess how long the event cycle takes to process the selected list.
      setTimeout(() => {
        this.applyLabel();
      }, 200);
    },
    getPortalRolesFromSubGroup(subGroup) {
      const portalRolesList = [subGroup.portalRole];
      if (this.includeOtpFromGuardianInSubGroup && subGroup.portalRole === portalRoles.GUARDIAN) {
        portalRolesList.push(portalRoles.OTP);
      }
      return portalRolesList;
    },
    hideProfileDropdownItems(item) {
      const itemGroup = this.storedResults.find(res => res.value === 'groups');
      itemGroup.options = itemGroup.options.filter(
        sel => !(sel.fromSubGroup && sel.groupId == item.id && sel.portalRole === item.portalRole)
      );
    },
    selectAllGroupOptions(selected, group) {
      // When clicking on checkbox next to the group options header, select/deselect all belonging options
      for (const option of group.options) {
        if (selected) {
          if (this.selectedUsersAndGroups.filter(s => s.value == option.value).length == 0) {
            this.selectedUsersAndGroups.push(option);
          }
        } else {
          const index = this.selectedUsersAndGroups.findIndex(item => item.value === option.value);
          if (index > -1) {
            this.selectedUsersAndGroups.splice(index, 1);
          }
        }
      }
      this.emitSelected(this.selectedUsersAndGroups);
    },
    checkSelectAllGroupOptions(group) {
      let isSelectAll = true;
      group.options.map(opt => {
        if (!this.selectedUsersAndGroups.includes(opt)) {
          isSelectAll = false;
        }
      });
      return isSelectAll;
    },
    expandGroupPortalRolesOnKeyDown() {
      // Accessibility: When navigating with arrows, expand the subgroups if there are any
      let element = $('.el-select-dropdown:visible .el-select-dropdown__item.hover[data-type="group"]');
      if (element.attr('data-parent') == null) {
        element = element.prev();
      }

      if (element.attr('data-parent') === 'true' && this.storedResults.find(res => res.value === 'groups')) {
        const groupId = element.attr('data-id');
        for (const group of this.storedResults.find(res => res.value === 'groups').options) {
          if (group.id == groupId && group.portalRole != null && group.type === 'group') {
            group.isHidden = false;
          }
        }
      }
    },
    closeSearch() {
      this.storedResults = [];
      this.queryString = '';
    },
    removeItem(item) {
      this.selectedUsersAndGroups = this.selectedUsersAndGroups.filter(i => i.value != item.value);
      this.applyLabel();
    },
    removeItems(items) {
      this.selectedUsersAndGroups = this.selectedUsersAndGroups.filter(i => !items.includes(i.value));
      this.applyLabel();
    },
    triggerResetValueOfSearchBox() {
      this.$refs.aulaSearchCustom.$refs.select.query = '';
      this.$refs.aulaSearchCustom.$refs.select.previousQuery = '';
    },
    uniquePush(items, item) {
      if (item.type == docTypes.PROFILE.toLowerCase()) {
        if (item.mailBoxOwnerType === messageOwnerTypes.OTP_INBOX) {
          if (items.every(entity => entity.otpInboxId !== item.otpInboxId)) {
            items.push(item);
          }
        } else if (this.isUniqueProfile(items, item)) {
          items.push(item);
        }
      } else {
        if (items.filter(it => it.value == item.value).length == 0) {
          items.push(item);
        }
      }
      return items;
    },
    isUniqueProfile(items, item) {
      if (item.fromGroup || item.fromSubGroup) {
        if (this.onlyUniqueProfiles) {
          return (
            items.filter(it => it.value == item.value).length == 0 &&
            ((!this.includeSelf &&
              this.institutions.filter(inst => inst.institutionProfileId == item.id).length == 0) ||
              this.includeSelf)
          );
        } else {
          return (
            items.filter(it => it.value == item.value).length == 0 &&
            ((!this.includeSelf &&
              this.institutions.filter(inst => inst.institutionProfileId == item.id).length == 0) ||
              this.includeSelf)
          );
        }
      } else {
        if (this.onlyUniqueProfiles) {
          return (
            items.filter(it => it.profileId != null && item.profileId != null && it.profileId == item.profileId)
              .length == 0
          );
        } else {
          return items.filter(it => it.value == item.value).length == 0;
        }
      }
    },
    dontSelectSubGroup(latest) {
      // When subgroup select is disabled, we remove it from the selectedUsersAndGroups array
      this.selectedUsersAndGroups = this.selectedUsersAndGroups.filter(sel => {
        if (sel.id == latest.id && sel.type == 'group' && sel.portalRole == latest.portalRole) {
          return false;
        }
        return true;
      });
      if (this.storedResults.find(res => res.value == 'groups') != null) {
        for (const group of this.storedResults.find(res => res.value == 'groups').options) {
          if (group.id == latest.id && group.portalRole == latest.portalRole && group.type == 'group') {
            group.isSelected = false;
          }
        }
      }
    },
    unselectAllSubGroups() {
      // If no elements have been selected, the subgroups should never be selected
      if (this.storedResults.find(res => res.value == 'groups') != null) {
        for (const group of this.storedResults.find(res => res.value == 'groups').options) {
          if (group.portalRole != null && group.type == 'group') {
            group.isSelected = false;
          }
        }
      }
    },
    dontSelectGroupButShowSubGroups(latest) {
      // If group select is disabled, we remove the group from the selectedUsersAndGroups array, and instead show/hide the belonging subgroups
      this.selectedUsersAndGroups = this.selectedUsersAndGroups.filter(sel => {
        if (sel.id == latest.id && sel.portalRole == latest.portalRole && sel.type == 'group') {
          return false;
        }
        return true;
      });
      if (this.storedResults.find(res => res.value == 'groups') != null) {
        for (const group of this.storedResults.find(res => res.value == 'groups').options) {
          if (group.id == latest.id && group.portalRole != null && group.type == 'group') {
            group.isHidden = !group.isHidden;
          } else if (group.groupId == latest.id && group.fromSubGroup && group.type == 'profile') {
            group.isHidden = !group.isHidden;
          } else if (group.id == latest.id && group.portalRole == null && group.type == 'group') {
            group.isExpanded = !group.isExpanded;
            if (group.isExpanded) {
              group.icon = 'el-select__caret el-input__icon el-icon-arrow-up';
            } else {
              group.icon = 'el-select__caret el-input__icon el-icon-arrow-down';
            }
          }
        }
      }
    },
    checkForDuplicateGroupsAndSubGroups(selected, oldItems) {
      // Make sure that we don't both select a group and all subgroups
      const selectedGroups = selected.filter(g => g.type == 'group');
      if (selectedGroups.length > 0) {
        let processedGroupId = null;
        for (const selectedGroup of selectedGroups) {
          if (processedGroupId == null || processedGroupId != selectedGroup.id) {
            processedGroupId = selectedGroup.id;
            const selectedWholeGroup = selected.filter(g => g.id == selectedGroup.id && g.class == 'parent');
            if (selectedWholeGroup.length > 0) {
              if (oldItems.filter(g => g.id == selectedGroup.id && g.class == 'parent').length == 0) {
                selected = selected.filter(
                  g => g.id != selectedGroup.id || (g.class != 'child' && g.id == selectedGroup.id)
                );
              } else if (selected.filter(g => g.id == selectedGroup.id && g.class == 'child').length > 0) {
                selected = selected.filter(g => g.value !== selectedGroup.value);
              }
            }
          }
        }
      }
      return selected;
    },
    handleSubGroupMembers(profile) {
      const groupMembers = this.groupOptions.filter(
        option =>
          option.type === userTypes.PROFILE &&
          option.groupId === profile.groupId &&
          option.portalRole === profile.portalRole
      );
      const hasSelectedSubGroup = this.selectedUsersAndGroups?.some(
        item => item.type === userTypes.GROUP && item.id === profile.groupId && item.portalRole === profile.portalRole
      );

      if (hasSelectedSubGroup) {
        this.removeSubGroupSelection(groupMembers, profile);
      } else {
        this.addSubGroupSelection(groupMembers, profile.groupId, profile.portalRole);
      }
    },
    removeSubGroupSelection(groupMembers, profile) {
      const latestProfileIndex = this.selectedUsersAndGroups.length - 1;
      const otherGroupMembers = groupMembers.filter(member => member.id !== profile.id);
      const selectedGroupIndex = this.selectedUsersAndGroups.findIndex(
        item => item.type === userTypes.GROUP && item.id === profile.groupId && item.portalRole === profile.portalRole
      );

      this.selectedUsersAndGroups.splice(latestProfileIndex, 1);
      this.selectedUsersAndGroups.splice(selectedGroupIndex, 1);
      this.selectedUsersAndGroups.push(...otherGroupMembers);
    },
    addSubGroupSelection(groupMembers, groupId, portalRole) {
      const selectedGroupMembers = this.selectedUsersAndGroups.filter(
        item => item.type === userTypes.PROFILE && item.groupId === groupId && item.portalRole === portalRole
      );

      if (selectedGroupMembers.length !== groupMembers.length) {
        return;
      }

      for (let index = this.selectedUsersAndGroups.length - 1; index >= 0; index--) {
        const item = this.selectedUsersAndGroups[index];
        if (item.type === userTypes.PROFILE && item.groupId === groupId && item.portalRole === portalRole) {
          this.selectedUsersAndGroups.splice(index, 1);
        }
      }

      const group = this.groupOptions.find(
        item => item.type === userTypes.GROUP && item.id === groupId && item.portalRole === portalRole
      );
      this.selectedUsersAndGroups.push(group);
    },
    handleSelection(selection) {
      if (!selection) {
        return;
      }

      if (selection.type === userTypes.PROFILE && selection.groupId != null) {
        this.handleSubGroupMembers(selection);
      } else if (selection.type === userTypes.GROUP) {
        this.handleGroupSelection(selection);
      }
    },
    handleGroupSelection(selection) {
      for (let index = this.selectedUsersAndGroups.length - 1; index >= 0; index--) {
        const item = this.selectedUsersAndGroups[index];
        if (
          item.type === userTypes.PROFILE &&
          item.portalRole === selection.portalRole &&
          item.groupId === selection.id
        ) {
          this.selectedUsersAndGroups.splice(index, 1);
        }
      }
    },
    emitSelected(selected) {
      if (this.isSearchComponentLoading) {
        return;
      }
      // Handle functionality when some of the group options aren't selectable
      const selectedOption = selected[selected.length - 1];
      this.handleSelection(selectedOption);
      const latest = this.selectedUsersAndGroups[this.selectedUsersAndGroups.length - 1];

      if (latest != null && latest.type == 'group' && latest.portalRole == null && this.splitGroupsParticipants) {
        if (!this.selectedBeforeChange.includes(latest.value)) {
          this.dontSelectGroupButShowSubGroups(latest);
          return;
        }
      } else if (latest != null && latest.type == 'group' && latest.portalRole != null && this.disableSubGroupSelect) {
        this.selectedBeforeChange = this.multiple
          ? this.selectedUsersAndGroups.map(g => g.value)
          : [this.selectedUsersAndGroups.value];
        this.dontSelectSubGroup(latest);
      } else if (selected.length == 0 && this.disableSubGroupSelect) {
        this.unselectAllSubGroups();
      }
      this.selectedBeforeChange = this.multiple
        ? this.selectedUsersAndGroups.map(g => g.value)
        : [this.selectedUsersAndGroups.value];
      // Continue with normal select
      if (!this.remoteSearch) {
        this.$emit('emitSelected', selected);
      } else {
        if (this.closeDropdownAfterSelect) {
          this.queryString = '';
        }
        if (Array.isArray(selected) && selected.length > 0) {
          const oldItems = this.selectedUsersAndGroups;
          let tempSelected = [];
          let counter = 0;
          // If user selects all three group portal roles, we add the entire group
          selected = this.checkForDuplicateGroupsAndSubGroups(selected, oldItems);

          for (const item of selected) {
            if (
              item.type == 'group' &&
              item.value &&
              item == latest &&
              ((item.value.includes('-members') && this.fromModule == moduleTypes.GROUPS) ||
                (this.inviteMembersWhenSelectingGroup && item.portalRole == null) ||
                (this.inviteMembersWhenSelectingGroupPortalRole && item.portalRole != null))
            ) {
              if (this.inviteGroupWhenAddingMembers) {
                tempSelected = this.uniquePush(tempSelected, item);
              }
              const filterRole = item.portalRole || 'members';
              const params = {
                groupId: item.id,
                activeInstitutionCode: this.activeInstitutionCodeForGroupMemberships,
              };
              if (filterRole != 'members') {
                params.portalRoles = this.getPortalRolesFromSubGroup(item);
              }

              params.isSearchComponentLoading = true;
              this.loadGroupMemberships(params).then(() => {
                tempSelected = this.addMembersFromGroup(tempSelected, filterRole, item.id);
                counter++;
                if (counter == selected.length) {
                  this.sendSelectedItems(tempSelected);
                }
              });
            } else {
              tempSelected = this.uniquePush(tempSelected, item);
              counter++;
              if (counter == selected.length) {
                this.sendSelectedItems(tempSelected);
              }
            }
          }
        } else {
          this.selectedUsersAndGroups = [];
          this.sendSelectedItems(selected);
        }
      }
    },
    emitFocus() {
      this.$emit('emitFocus', true);
    },
    emitRemoveTag(event) {
      this.$emit('emitRemoveTag', event);
    },
    addMembersFromGroup(items, filterRole, groupId) {
      for (const obj of this.groupMemberships) {
        for (const member of obj.institutionProfiles) {
          if (
            this.selectedUsersAndGroups.find(
              item => item.type == docTypes.PROFILE.toLowerCase() && item.id == member.institutionProfileId
            ) == null
          ) {
            member.id = member.institutionProfileId;
            member.fromGroup = true;
            member.groups = [{ id: groupId }];
            member.type = docTypes.PROFILE.toLowerCase();
            member.label = member.fullName + ' (' + member.metadata + ')';
            if (member.otpInboxId != null) {
              member.value = messageOwnerTypes.OTP_INBOX + member.otpInboxId + member.institutionCode;
            } else {
              member.value = docTypes.PROFILE.toLowerCase() + member.id + member.institutionCode;
            }
            member.portalRole = obj.portalRole;
            if (this.filterPortalRolesWhenAddingMembers.length > 0) {
              if (this.filterPortalRolesWhenAddingMembers.includes(member.portalRole)) {
                items = this.uniquePush(items, member);
              }
            } else if (filterRole == 'members' || (filterRole !== 'members' && member.portalRole == filterRole)) {
              items = this.uniquePush(items, member);
            }
          } else {
            for (const selected of this.selectedUsersAndGroups) {
              if (selected.type == docTypes.PROFILE.toLowerCase() && selected.id == member.institutionProfileId) {
                if (selected.groups != null && selected.groups.find(group => group.id == groupId) == null) {
                  selected.groups.push({ id: groupId });
                }
              }
            }
          }
        }
      }
      return items;
    },
    sendSelectedItems(selectedItems) {
      this.loading = false;
      if (
        Array.isArray(selectedItems) &&
        !this.showMembersFromGroup &&
        (this.inviteMembersWhenSelectingGroup || this.inviteMembersWhenSelectingGroupPortalRole)
      ) {
        selectedItems = this.onlyKeepMembersIfGroupIsSelected(selectedItems);
        this.allSelectedMembers = selectedItems.filter(item => item.fromGroup);
        this.selectedUsersAndGroups = selectedItems.filter(item => item.fromGroup == null);
      } else {
        this.selectedUsersAndGroups = selectedItems;
      }
      if (this.multiple) {
        if (this.instance !== null) {
          this.$emit('emitSelected', {
            selectedItems: selectedItems,
            instance: this.instance,
          });
        } else {
          this.$emit('emitSelected', selectedItems);
        }
      } else {
        if (this.instance !== null) {
          this.$emit('emitSelected', {
            selectedItems: [selectedItems],
            instance: this.instance,
          });
        } else {
          this.$emit('emitSelected', [selectedItems]);
        }
      }
      this.showingChildRelations = false;
      this.triggerResetValueOfSearchBox();
      if (
        this.closeDropdownAfterSelect ||
        (!this.closeDropdownAfterSelect &&
          [].concat.apply(
            [],
            this.storedResults.map(res => res.options)
          ).length == 1)
      ) {
        this.storedResults = [];
      }
      this.applyLabel();
    },
    onlyKeepMembersIfGroupIsSelected(selectedItems) {
      for (const member of this.allSelectedMembers) {
        for (const memberGroup of member.groups) {
          if (
            selectedItems.find(
              item =>
                item.type === docTypes.GROUP.toLowerCase() &&
                item.id === memberGroup.id &&
                item.portalRole === member.portalRole
            ) != null
          ) {
            const existingMember = selectedItems.find(item => {
              let isSameMemberId = item.id === member.id;
              if (item.otpInboxId != null) {
                isSameMemberId = member.otpInboxId === item.otpInboxId;
              }
              return item.type === docTypes.PROFILE.toLowerCase() && isSameMemberId && item.fromGroup;
            });
            if (existingMember != null) {
              if (existingMember.groups) {
                if (!existingMember.groups.some(existingMemberGroup => existingMemberGroup.id === memberGroup.id)) {
                  existingMember.groups.push(memberGroup);
                }
              } else {
                existingMember.groups = [memberGroup];
              }
            } else {
              selectedItems.push(member);
            }
          }
        }
      }
      return selectedItems;
    },
    applyLabel() {
      setTimeout(() => {
        if (Array.isArray(this.$refs.aulaSearchCustom?.$refs?.select?.selected)) {
          for (const selected of this.$refs.aulaSearchCustom.$refs.select.selected) {
            if (selected && !selected.currentLabel) {
              selected.currentLabel = selected.value.label;
            }
          }
          this.$refs.aulaSearchCustom.$refs.select.resetInputHeight();
        }
      }, 200);
    },
    loadChildRelations(institutionProfileId) {
      this.loading = true;
      let child = null;
      for (const result of this.storedResults) {
        if (result.options.find(item => item.id == institutionProfileId) != null) {
          child = result.options.find(item => item.id == institutionProfileId);
        }
      }
      const groups = [];
      const guardians = [];
      const groupHomes = [];
      const children = [];
      const employees = [];
      if (child != null) {
        const childLabel =
          child.name != null
            ? child.name
            : '' + child.institutionName != null
            ? ' (' + child.institutionName + ')'
            : '';
        children.push({
          label: child.label ? child.label : childLabel,
          value: 'profile' + child.id + child.institutionCode,
          name: child.name,
          id: child.id,
          type: 'profile',
          institutionCode: child.institutionCode,
          email: child.email,
          icon: 'icon-Aula_elipses get-child-relatives',
          profileId: child.profileId,
          portalRole: child.portalRole,
        });

        if (child.groupHomes) {
          child.groupHomes.forEach(groupHome => {
            groupHomes.push({
              label: Vue.filter('groupHomeRelationLabel')(groupHome, child),
              value: 'groupHome-' + groupHome.id + '-' + groupHome.otpInboxId,
              name: groupHome.name,
              id: groupHome.id,
              type: 'groupHome',
              otpInboxId: groupHome.otpInboxId,
              mailBoxOwnerType: messageOwnerTypes.OTP_INBOX,
              regardingChild: child.id,
              institutionCode: child.institutionCode,
              portalRole: portalRoles.OTP,
            });
          });
        }

        if (child.relatedProfiles != null) {
          for (const relation of child.relatedProfiles) {
            const name = relation.firstName + ' ' + relation.lastName;
            const entry = {
              label: `${name} (${relation.metadata})`,
              value: 'profile' + relation.id + child.institutionCode,
              name: name,
              id: relation.id,
              type: 'profile',
              institutionCode: child.institutionCode,
              email: relation.email,
              profileId: relation.profileId,
            };
            switch (relation.relationType) {
              case 'teacher':
                entry.portalRole = portalRoles.EMPLOYEE;
                employees.push(entry);
                break;
              case 'guardian':
                entry.portalRole = portalRoles.GUARDIAN;
                guardians.push(entry);
                break;
            }
          }
        }
        if (child.relatedGroups != null) {
          for (const relation of child.relatedGroups) {
            groups.push({
              label: `${relation.name} (${child.institutionName})`,
              value: 'group' + relation.id + child.institutionCode,
              name: relation.name,
              id: relation.id,
              type: 'group',
              institutionCode: child.institutionCode,
            });
          }
        }
        const childGrouping = {
          label: 'Barn',
          options: children,
          value: 'children',
        };
        const employeesGrouping = {
          label: 'Medarbejdere til ' + child.name,
          options: employees,
          value: 'employees',
        };
        const guardiansGrouping = {
          label: 'Forældre til ' + child.name,
          options: guardians,
          value: 'guardians',
        };
        const groupHomesGrouping = {
          label: 'Andre for ' + child.name,
          options: groupHomes,
          value: 'groupHomes',
        };
        const groupsGrouping = {
          label: 'Grupper',
          options: groups,
          value: 'groups',
        };
        this.storedResults = [childGrouping, guardiansGrouping, groupHomesGrouping, employeesGrouping, groupsGrouping];
      }
      this.loading = false;
    },
    loadResults(query) {
      this.queryString = query;
      this.triggerResetValueOfSearchBox();
      this.storedResults = [];
      if (query.length > 0) {
        this.$refs.aulaSearchCustom.$refs.select.query = query;
        if (this.instance !== null) {
          this.searchResultLoader(query, this.instance);
        } else {
          this.searchResultLoader(query);
        }
        this.loading = true;
      } else {
        this.storedResults = [];
      }
    },
    expandGroupMembers(index, portalRole, groupId) {
      for (const obj of this.groupMemberships) {
        let addedMember = 0;
        for (const member of obj.institutionProfiles) {
          if (
            (this.fromModule == moduleTypes.EVENT && this.calendarActiveInstProfileId == member.institutionProfileId) ||
            (!this.includeSelf &&
              this.institutions.find(inst => inst.institutionProfileId == member.institutionProfileId))
          ) {
            continue;
          }
          if (
            portalRole == portalRoles.MEMBERS ||
            (portalRole != portalRoles.MEMBERS && obj.portalRole == portalRole)
          ) {
            member.id = member.institutionProfileId;
            member.type = docTypes.PROFILE.toLowerCase();
            member.fromSubGroup = true;
            member.label = member.fullName + ' (' + member.metadata + ')';
            if (member.otpInboxId != null) {
              member.value = messageOwnerTypes.OTP_INBOX + member.otpInboxId + member.institutionCode;
            } else {
              member.value = docTypes.PROFILE.toLowerCase() + member.id + member.institutionCode;
            }
            member.portalRole = obj.portalRole;
            member.groupId = groupId;
            member.groups = [{ id: groupId }];
            member.class = 'child-member';
            if (this.disablePortalRolesMembersWhenExpandingGroup.includes(portalRole)) {
              member.disabled = true;
            }
            addedMember++;
            this.storedResults.find(res => res.value == 'groups').options.splice(index + addedMember, 0, member);
          }
        }
      }
    },
    findWithAttr(array, attr, value, attr2 = null, value2 = null) {
      for (let i = 0; i < array.length; i += 1) {
        if (attr2 != null && value2 != null) {
          if (array[i][attr] == value && array[i][attr2] === value2) {
            return i;
          }
        } else {
          if (array[i][attr] == value) {
            return i;
          }
        }
      }
      return -1;
    },
    mapExistingParticipants() {
      let filteredExistingParticipants = cloneDeep(this.existingParticipants);
      if (this.existingParticipants.length > 0) {
        filteredExistingParticipants = filteredExistingParticipants.filter(
          participant => participant.isHiddenFromSearchArea !== true
        );
      }
      if (this.multiple) {
        if (filteredExistingParticipants.length > 0) {
          this.selectedUsersAndGroups = filteredExistingParticipants.map(function (e) {
            if (typeof e == 'string') {
              return e;
            }
            const value = e.value || e.id;
            let label = e.label != null ? e.label : e.name != null ? e.name : e.fullName != null ? e.fullName : e.title;
            if (e.metadata) {
              label = Vue.filter('displayProfileNameWithMetadata')(e);
            }
            e.label = label;
            e.value = isNaN(Number(value)) ? value.toString() : Number(value); // make it number if convertible, otherwise this element will be unchecked
            return e;
          });
          this.selectedBeforeChange = this.selectedUsersAndGroups.map(g => g.value);
          this.applyLabel();
        } else if (filteredExistingParticipants.length == 0) {
          this.selectedUsersAndGroups = [];
        }
      } else {
        if (this.selectedUsersAndGroups.length == 0) {
          if (filteredExistingParticipants.length > 0) {
            this.selectedUsersAndGroups = filteredExistingParticipants[0];
            this.selectedBeforeChange = [this.selectedUsersAndGroups.value];
            this.applyLabel();
          }
        }
      }
    },
  },
  watch: {
    setFocus(val) {
      if (val) {
        this.$el.getElementsByClassName('el-input__inner')[0].focus();
      }
    },
    results(results) {
      this.loading = false;
      if (!this.showingChildRelations) {
        this.storedResults = results;
      }
    },
    queryString(value) {
      this.showingChildRelations = false;
      if (value.length > 0) {
        const inputElement = this.$el.querySelector(`#aulaSearchCustom-${this.uniqueId} .el-select__input`);
        if (inputElement) {
          inputElement.style.minWith = '100px';
        }
      }
    },
    resetSearchInput() {
      if (this.instanceToReset != null) {
        if (this.instance == this.instanceToReset) {
          this.selectedUsersAndGroups = [];
          this.storedResults = [];
        }
      } else {
        if (this.multiple) {
          this.selectedUsersAndGroups = [];
        } else {
          this.selectedUsersAndGroups = null;
        }
        this.storedResults = [];
      }
    },
    resetSelected() {
      if (this.multiple) {
        this.selectedUsersAndGroups = [];
      } else {
        this.selectedUsersAndGroups = null;
      }
    },
    closeSearchResult() {
      this.closeSearch();
    },
    existingParticipants() {
      if (this.addExistingParticipants) {
        this.mapExistingParticipants();
      }
    },
  },
  mounted() {
    const self = this;

    this.$eventHub.$on('removeAulaSearchItem', this.removeItem);
    this.$eventHub.$on('removeAulaSearchItems', this.removeItems);
    if (!this.remoteSearch) {
      this.storedResults = this.results;
    }
    if (this.existingParticipants != null && this.addExistingParticipants) {
      this.mapExistingParticipants();
    }
    $('.el-select__input').focusout(function () {
      $(this).css('min-width', 'unset');
      self.applyLabel();
    });
  },
  components: {
    IconContainer,
    Icon,
    AulaButton,
    KeywordHighlight,
  },
};
</script>
<style scoped lang="scss">
@import '../../shared/assets/scss/components/search/aula-search.scss';
</style>
