









































import AulaImg from '../../../shared/components/AulaImg.vue';
import AulaButton from '../../../shared/components/AulaButton.vue';
import IconContainer from '../../../shared/components/IconContainer.vue';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';

const PORTRAIT = '50% 4%';
const PORTRAIT_REVERSE = '50% 96%';
const LANDSCAPE = '50% 50%';
const LANDSCAPE_RIGHT = '4% 50%';
const LANDSCAPE_LEFT = '96% 50%';

export default {
  components: { Icon, IconContainer, AulaButton, AulaImg },
  props: {
    file: { type: File, default: null },
  },
  emits: ['rotate', 'delete'],
  data() {
    return {
      rotationDegree: 0,
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    imageThumbnaiUrl() {
      return URL.createObjectURL(this.file);
    },
  },
  methods: {
    rotate(degree) {
      const width = this.$refs.imageThumbnail.$el.naturalWidth;
      const height = this.$refs.imageThumbnail.$el.naturalHeight;
      const isPortrait = (width / height) < 1;
      let position = '';

      this.rotationDegree = this.rotationDegree + degree;
      this.$refs.imageThumbnail.$el.style.transform = 'rotate(' + this.rotationDegree + 'deg)';
      let angle = this.rotationDegree % 360;
      if (angle < 0) {
        angle += 360;
      }

      // Adjust preview position to match the new profile picture when uploaded
      switch (angle) {
        case 0:
          position = isPortrait ? PORTRAIT : LANDSCAPE;
          break;
        case 90:
          position = isPortrait ? LANDSCAPE : LANDSCAPE_RIGHT;
          break;
        case 180:
          position = isPortrait ? PORTRAIT_REVERSE : LANDSCAPE;
          break;
        case 270:
          position = isPortrait ? LANDSCAPE : LANDSCAPE_LEFT;
          break;
        default:
          break;
      }
      this.$refs.imageThumbnail.$el.style.objectPosition = position;

      return this.$emit('rotate', angle);
    },
    deleteClicked() {
      return this.$emit('delete');
    },
  },
};
