<template>
  <div class="aula-profile-container page-layout-padding" data-view="mobile">
    <div v-if="!isMobile" class="d-flex">
      <h1 class="contact-info-title mr-auto">
        {{ 'CONTACT_INFO' | fromTextKey }}
      </h1>
    </div>
    <p v-if="isDeactivated">{{ 'PROFILE_DEACTIVATED_DESCRIPTION' | fromTextKey }}</p>
    <AulaNavigationToolbar v-if="canViewSharedInformation && !isMobile" class="tabs-nav">
      <AulaButtons>
        <AulaButton
          v-for="(tab, index) in contactTabs"
          :key="index"
          fill="clear"
          class="tab-item text-uppercase"
          :class="{ 'active-tab': tab === activeContactTab }"
          @click="setContactTab(tab)"
        >
          {{ tab.name }}
        </AulaButton>
      </AulaButtons>
    </AulaNavigationToolbar>
    <div v-if="contactsTabValueTypes.information === activeContactTab.value">
      <p v-if="parent === parentTypes.PROFILE && profile.role === portalRoles.EMPLOYEE" class="help-text">
        {{ $t('contact.employee_contact_information_page_description') }}
      </p>
      <AulaList v-if="canViewSharedInformation && isMobile" class="mb-5">
        <AulaListItem v-button class="font-weight-bold" @click="openConsentModal">
          {{ 'CONTACT_SHARED_INFORMATION' | fromTextKey }}
          <template #end>
            <Icon :name="iconClassEnum.FORWARD_ARROW" />
          </template>
        </AulaListItem>
      </AulaList>
      <ContactInformation
        v-if="!isFromAdmin"
        :profile="selectedProfile"
        :is-loading="isLoading"
        :can-edit="canEditContactInformation"
        :show-relations="canShowRelations"
        @saved="onSavedContactInformation"
      />
      <template v-if="isFromAdmin && selectedInstitutionProfile">
        <ContactInformationMainContact
          class="mt-4 mb-3"
          :profile="selectedInstitutionProfile"
          :show-blocked-status="canHandleBlockedCommunication"
          @toggleBlockedCommunication="updateBlockCommunicationState"
        />
        <UserDetailsPortalAdministrationTabs class="mt-3" :institution-profile="selectedInstitutionProfile" />
      </template>
    </div>
    <Consent
      v-else-if="contactsTabValueTypes.sharedInformation === activeContactTab.value"
      @close="goToInformationTab"
    />
    <aula-modal
      ref="lockFormModal"
      :header-text="lockUserModalTitle"
      :ok-text="lockUserSubmitText"
      :is-loading="isSubmittingLockReason"
      css-class="aula-modal-lg"
      @cancelClicked="onCancelLockReason"
      @closeClicked="onCancelLockReason"
      @okClicked="onSubmitLockReason"
    >
      <UserDetailsPortalAdministrationLockUserForm
        :value="lockReason"
        :show-max-characters-exceeded-on-lock-reason="showMaxCharactersExceededOnLockReason"
        @input="onUpdateLockReason"
      />
    </aula-modal>
  </div>
</template>
<script>
import { types } from '../../src/store/types/types';
import { types as adminTypes } from '../../src_adm/store/types/types';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { portalRoles } from '../enums/portalRoles';
import { parentTypes } from '../enums/parentTypes.ts';
import { permissionEnum } from '../enums/permissionEnum.ts';
import { institutionTypes } from '../enums/institutionTypes';
import $ from 'jquery';
import UserDetailsPortalAdministrationTabs from './UserDetailsPortal/UserDetailsAdministrationTabs';
import { UserDetailsPortalProvideKey } from './UserDetailsPortal/UsersDetailsPortalProvideKey';
import UserDetailsPortalAdministrationLockUserForm from './UserDetailsPortal/UserDetailsAdministrationLockUserForm';
import { lockUserConstant } from './UserDetailsPortal/constants/lockUser';
import { CallbackRegistryService } from '../services/CallbackRegistryService';
import { lockStatusEnum } from '../enums/lockStatusEnum';
import { providerKeyEnum } from '../enums/providerKeyEnum';
import AulaButton from './AulaButton.vue';
import AulaNavigationToolbar from './navigation/AulaNavigationToolbar.vue';
import AulaButtons from './AulaButtons.vue';
import { contactsTabValueTypes } from './UserDetailsPortal/constants/contactsTabValueTypes';
import AulaList from './AulaList.vue';
import AulaListItem from './AulaListItem.vue';
import Icon from './Icon.vue';
import { iconClassEnum } from '../enums/iconClassEnum';
import Button from '../directives/button';
import Consent from '../../src/components/consent/Consent.vue';
import ContactInformation from '../../src/components/contacts/ContactInformation.vue';
import ContactInformationMainContact from '../../src/components/contacts/ContactInformationMainContact.vue';
import { errorSubCodeTypes } from '../enums/errorSubCodeTypes';

export default {
  provide() {
    return {
      [UserDetailsPortalProvideKey.onInstitutionGroupClick]: group => {
        this.loadGroupWithAll({
          groupId: group.id,
          institutionCode: group.institutionCode,
          fromAdministration: true,
        }).then(() => {
          this.$router.push({ name: 'editGroup', params: { id: group.id } });
        });
      },
      [UserDetailsPortalProvideKey.setShowLockReasonForm]: event => {
        this.$refs.lockFormModal.show();
        this.lockUserShowLockEvent = event;
      },
      [UserDetailsPortalProvideKey.refreshDataTableCallbackService]: this.refreshDataTableCallbackService,
      [UserDetailsPortalProvideKey.setInstitutionProfile]: institutionProfile =>
        (this.lockUserInstitutionProfile = institutionProfile),
      [providerKeyEnum.hasLockPermission]: () =>
        this.hasPermissionOnInstitution(permissionEnum.HANDLE_USER_LOCK, this.activeProfile.institutionCode),
    };
  },
  data() {
    return {
      iconClassEnum,
      contactsTabValueTypes,
      contactTabs: [
        {
          name: this.$options.filters.fromTextKey('CONTACT_INFORMATION'),
          value: contactsTabValueTypes.information,
        },
        {
          name: this.$options.filters.fromTextKey('CONTACT_SHARED_INFORMATION'),
          value: contactsTabValueTypes.sharedInformation,
        },
      ],
      selectedPrimaryInstitution: null,
      lockStatusEnum,
      portalRoles: portalRoles,
      editMode: false,
      parent: parentTypes.PROFILE,
      parentTypes: parentTypes,
      permissionEnum: permissionEnum,
      institutionTypes: institutionTypes,
      isSubmittingLockReason: false,
      lockReason: '',
      showMaxCharactersExceededOnLockReason: false,
      refreshDataTableCallbackService: new CallbackRegistryService(),
      lockUserInstitutionProfile: null,
      lockUserShowLockEvent: null,
      isLoading: true,
      isDeactivated: false,
    };
  },
  computed: {
    ...mapGetters({
      loggedInProfile: types.GET_CURRENT_PROFILE,
      profile: types.GET_CURRENT_PROFILE,
      activeProfile: types.GET_ACTIVE_PROFILE,
      isProfileLoaded: types.IS_PROFILE_LOADED,
      selectedProfile: types.GET_SELECTED_PROFILE,
      selectedInstitution: types.GET_SELECTED_INSTITUTION,
      institutions: types.GET_INSTITUTIONS,
      isMobile: types.GET_IS_MOBILE,
      hasPermission: types.HAS_PERMISSION,
      hasPermissionOnInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
    }),
    canShowRelations() {
      if (this.parent === parentTypes.SEARCH) {
        return true;
      }
      switch (this.loggedInProfile.role) {
        case portalRoles.OTP:
          return false;
        case portalRoles.CHILD:
          return this.profile.profileId !== this.loggedInProfile.profileId;
        default:
          return true;
      }
    },
    isFromAdmin() {
      return this.parent === parentTypes.ADMINISTRATION;
    },
    selectedInstitutionProfile() {
      if (!Array.isArray(this.selectedProfile?.institutionProfiles)) {
        return null;
      }
      return this.selectedProfile.institutionProfiles[0];
    },
    canViewSharedInformation() {
      const validRoles = [portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.OTP];
      const isParentProfile = this.parent === parentTypes.PROFILE;
      return (
        validRoles.includes(this.profile.role.toLowerCase()) && isParentProfile && this.selectedProfile?.overConsentAge
      );
    },
    activeContactTab() {
      const tabValue = this.$route.query.tab;
      return this.contactTabs.find(tab => tab.value === tabValue) ?? this.contactTabs[0];
    },
    isLockUserInstitutionProfileLocked() {
      const institutionProfile = this.lockUserInstitutionProfile;
      if (institutionProfile == null) {
        return false;
      }

      return (
        institutionProfile.profileStatus === lockStatusEnum.LOCKED ||
        institutionProfile.profileStatus === lockStatusEnum.LOCKING
      );
    },
    lockUserSubmitText() {
      let name = 'USERS_LOCK_USER_CAUSE_SUBMIT';
      if (this.isLockUserInstitutionProfileLocked) {
        name = 'USERS_UNLOCK_USER_CAUSE_SUBMIT';
      }
      return name;
    },
    lockUserModalTitle() {
      let title = 'USERS_LOCK_USER_CAUSE_TITLE';
      if (this.isLockUserInstitutionProfileLocked) {
        title = 'USERS_UNLOCK_USER_CAUSE_TITLE';
      }
      return title;
    },
    canEditContactInformation() {
      if (this.parent !== parentTypes.PROFILE) {
        return false;
      }
      const institutionProfiles = this.selectedProfile.institutionProfiles;
      const institutionProfile = institutionProfiles?.find(
        institutionProfile => institutionProfile.institutionCode === this.selectedInstitution.institutionCode
      );
      return institutionProfile?.currentUserCanEditContactInformation;
    },
    canHandleBlockedCommunication() {
      return this.hasPermissionOnInstitution(permissionEnum.HANDLE_USER_ROLES, this.activeProfile.institutionCode);
    },
  },
  methods: {
    ...mapMutations({
      resetConsent: types.MUTATE_RESET_PROFILE_CONSENT,
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
      sendToastErrorMessage: types.MUTATE_ERROR_TEXT,
    }),
    ...mapActions({
      selectProfile: types.SELECT_PROFILE,
      loadAdditionalData: types.LOAD_PROFILE_ADDITIONAL_DATA,
      getConsentResponse: types.ACTION_GET_CONSENTS_RESPONSE_BY_INSTITUTION_PROFILEID,
      editBlockOfCommunication: types.EDIT_BLOCK_OF_COMMUNICATION_FOR_SELECTED_USER,
      loadGroupWithAll: types.SET_ACTIVE_GROUP,
      updateAdditionalData: types.UPDATE_ADDITIONAL_DATA_ON_BEHALF,
      createLockReason: adminTypes.CREATE_PROFILE_LOCK_REASON,
      getProfileContext: types.ACTION_GET_PROFILE_CONTEXT,
    }),
    updateBlockCommunicationState(state) {
      this.editBlockOfCommunication({
        id: this.selectedInstitutionProfile.profileId,
        state: state,
        municipalityCode: this.selectedInstitutionProfile.municipalityCode,
      });
    },
    onSavedContactInformation() {
      this.init();
      this.getProfileContext();
    },
    goToInformationTab() {
      const tab = this.contactTabs.find(t => t.value === contactsTabValueTypes.information);
      this.setContactTab(tab);
    },
    openConsentModal() {
      const tab = this.contactTabs.find(t => t.value === contactsTabValueTypes.sharedInformation);
      this.setContactTab(tab);
    },
    setContactTab(tab) {
      this.$router.push({ path: this.$route.path, query: { ...this.$route.query, tab: tab.value } });
    },
    async onSubmitLockReason() {
      if (this.lockUserInstitutionProfile == null) {
        throw Error('InstitutionProfile is not set for the targeted user');
      }
      const institutionProfile = this.lockUserInstitutionProfile;
      const isValid = this.validateLockUser();
      if (!isValid) {
        return;
      }

      try {
        const isLocked = this.isLockUserInstitutionProfileLocked;
        let lockStatus = lockStatusEnum.LOCKED;
        if (isLocked) {
          lockStatus = lockStatusEnum.UNLOCKED;
        }
        this.isSubmittingLockReason = true;
        await this.createLockReason({
          reason: this.lockReason,
          institutionProfileId: institutionProfile.id,
          lockStatus,
        });
        this.lockReason = '';
        this.refreshDataTableCallbackService.runCallbacks();
        this.$refs.lockFormModal.hide();
        this.selectProfile({
          instProfileIds: [institutionProfile.id],
          fromAdministration: this.parent === parentTypes.ADMINISTRATION,
        }).then(() => {
          this.editMode = false;
        });
      } catch (e) {
        this.sendToastErrorMessage('API_ERROR_GENERAL');
      } finally {
        this.isSubmittingLockReason = false;
      }
    },
    onCancelLockReason() {
      this.$refs.lockFormModal.hide();
      this.lockReason = '';
      this.lockUserShowLockEvent.target.focus();
    },
    onUpdateLockReason(lockReasonValue) {
      this.showMaxCharactersExceededOnLockReason = false;
      this.lockReason = lockReasonValue;
    },
    validateLockUser() {
      let isValid = true;
      if (this.getHasExceededMaxCharacterLimit()) {
        isValid = false;
      }
      return isValid;
    },
    getHasExceededMaxCharacterLimit() {
      let hasFailed = false;
      if (this.lockReason.length > lockUserConstant.LOCK_DESCRIPTION_CHARACTER_LIMIT) {
        this.showMaxCharactersExceededOnLockReason = true;
        hasFailed = true;
      }

      return hasFailed;
    },
    redirectToStepUp() {
      this.setStepUpNotification({
        showStepUpNotification: true,
        redirectedUrl: window.location.href,
      });
    },
    init() {
      const id = this.$route.params.id;
      if (id != null) {
        let ids = [this.$route.params.id];
        if (this.$route.query.parent) {
          this.parent = this.$route.query.parent;
        } else {
          if (this.$route.path.includes('/brugere')) {
            this.parent = parentTypes.ADMINISTRATION;
          } else if (this.institutions.filter(inst => inst.institutionProfileId == id).length > 0) {
            this.parent = parentTypes.PROFILE;
          } else {
            this.parent = parentTypes.SEARCH;
          }
        }
        if (this.parent == parentTypes.PROFILE) {
          ids = [];
          for (const inst of this.institutions) {
            ids.push(inst.institutionProfileId);
          }
        }

        this.isLoading = true;
        this.selectProfile(null); // clear user
        this.selectProfile({
          instProfileIds: ids,
          fromAdministration: this.parent == parentTypes.ADMINISTRATION,
        })
          .then(() => {
            this.editMode = false;
            this.updateSelectedPrimaryInstitution();
          })
          .catch(error => {
            if (error.response?.data.status.subCode === errorSubCodeTypes.deactivatedInstitutionProfile) {
              this.isDeactivated = true;
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    updateSelectedPrimaryInstitution() {
      this.selectedPrimaryInstitution = this.institutions.find(institution => institution.isPrimary);
    },
  },
  watch: {
    $route() {
      this.init();
    },
    isProfileLoaded() {
      this.init();
    },
  },
  mounted() {
    this.init();
    setTimeout(function () {
      $('.contact-info-title').focus();
    }, 500);
  },
  directives: {
    Button,
  },
  components: {
    ContactInformationMainContact,
    ContactInformation,
    Consent,
    Icon,
    AulaListItem,
    AulaList,
    AulaButtons,
    AulaNavigationToolbar,
    AulaButton,
    UserDetailsPortalAdministrationLockUserForm,
    UserDetailsPortalAdministrationTabs,
  },
};
</script>
<style scoped lang="scss">
@import '../assets/scss/core/variables';
@import '../assets/scss/core/breakpoints';

.functions {
  display: flex;
  justify-content: end;
}

.contact-info-title {
  outline: none;
}

hr {
  margin-top: 0;
}

ul {
  list-style: none;
  padding: 0;
}

.aula-profile-container {
  .help-text {
    margin-bottom: 40px;
    @include breakpoint-lg-down() {
      margin-top: 0px;
      margin-bottom: 20px;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.btn-collapse-link {
  background: none;
  padding: 0;
  text-indent: 10px;
}

.terms-policy {
  a {
    color: var(--color-primary-dark);
  }
}

.tabs-nav {
  margin-top: 5px;
  margin-bottom: 20px;
  .tab-item {
    font-size: 15px;
  }
}
</style>
