// USERS
export const USERS_LABEL_SEARCH = 'Søg:';
export const USERS_LABEL_SEARCH_USERS = 'Søg blandt brugere:';
export const USERS_LABEL_SEARCH_RESULTS = 'Resultat';
export const USER_TABLE_PICTURE = 'Billede';
export const USER_TABLE_TOOLKIT_LINK = 'Link til Uni-Login opslag';
export const USER_TABLE_NAME = 'Navn';
export const USER_TABLE_UNILOGIN = 'Unilogin';
export const USER_TABLE_PROFILE_ROLE = 'Profilrolle';
export const USER_TABLE_PROFILE_TYPE = 'Profiltype';
export const USER_TABLE_INSTITUTION = 'Institution';
export const USER_TABLE_MUNICIPALITY = 'Kommune';
export const USER_TABLE_NUMBER = 'Telefonnummer';
export const USER_TABLE_EMAIL = 'Email';
export const USER_TABLE_PERMISSIONS = 'Rettigheder';
export const USER_TABLE_EDIT = 'Rediger';
export const USER_TABLE_USER_ROLES = 'Roller og rettigheder';
export const USER_HEADER_LABEL = 'Brugere';
export const USERS_ASSIGN_ROLE_WARNING_TEXT = 'Du kan ikke tildele de valgte roller til nogle af de valgte brugere';
export const USERS_BUTTON_ADD_USER_ROLES = 'Tilføj Roller og Rettigheder';
export const USERS_BUTTON_REMOVE_USER_ROLES = 'Fjern Roller og Rettigheder';
export const USERS_BUTTON_SAVE_USER_ROLES = 'Gem';
export const USERS_WARNING_CANNOT_DELETE_SOME_USER_DATA =
  'Oplysningerne stammer fra UNI-login, og kan derfor ikke slettes.';

export const USERS_WARNING_TOO_MANY_RESULTS_FROM_SEARCH =
  'Der er for mange søgeresultater. Prøv at indsnævre din søgning yderligere';
export const USERS_WARNING_ZERO_RESULTS_FROM_SEARCH = 'Søgningen gav ikke nogle resultater. Prøv at søge bredere';
export const USERS_INTRO_TEXT_FOR_CENTRAL_ADMIN =
  'Du kan søge brugere frem ved at søge på deres navn, institution, kommune og roller.';
export const USERS_INTRO_TEXT_FOR_MUNICIPAL_ADMIN =
  'Du kan søge brugere frem ved at søge på deres navn, institution, roller og rettigheder.';
export const USERS_INTRO_TEXT_FOR_INSTITUTIONAL_ADMIN =
  'Du kan søge brugere frem ved at søge på deres navn, roller og rettigheder.';
export const IMPERSONATION_MISSING_REASON = 'Du mangler at angive en årsag';
export const IMPERSONATION_MISSING_CASE_ID = 'Du mangler at angive et sags id';

// USER
export const USER_DRAWER_TITLE = 'Brugeroplysninger';
export const USERS_SELECTED = 'valgt';
export const USERS_TITLE_EDIT_PROFILE_IMAGE = 'Rediger profilbillede';
export const USERS_LABEL_AGREE = 'Samtykker';
export const USERS_LABEL_ADDITIONAL_MASTER_DATA = 'Supplerende stamdata';
export const USERS_LABEL_LAST_LOGIN = 'Senseste Login';
export const USERS_LABEL_PROFILE = 'Profil';
export const USERS_LABEL_EMAIL = 'E-mail (kontakt)';
export const USERS_LABEL_EMAIL_AULA = 'Aula-e-mail';
export const USERS_LABEL_TELEPHONE = 'Telefon';
export const USERS_LABEL_ADDRESS = 'Adresse';
export const USERS_LABEL_CHILDREN = 'Børn';
export const USERS_LABEL_SIGNATURE = 'Signatur';
export const USERS_LABEL_ROLES = 'Rolle(r)';
export const USERS_LABEL_VIEW_ALL_DATA = 'Se alt data i Aula om';
export const USERS_TOASTR_DELETE_DATA =
  'Data bliver slettet. Der kan gå op til 1 minut før alt data er slettet og du ikke længere kan se det i tabellen';
export const USERS_TITLE_EDIT_ROLE = 'Rediger rolle';
export const USERS_LABEL_UPLOAD_BLOCK = 'Blokeret upload af medier';
export const USERS_LOCK_USER = 'Lås bruger';
export const USERS_UNLOCK_USER = 'Lås bruger op';
export const USERS_LOCK_USER_INCIDENT_UNLOCKED = 'Låst op';
export const USERS_LOCK_USER_INCIDENT_LOCKED = 'Låst';
export const USERS_LOCK_USER_INTRO =
  'Her kan du låse for en brugers institutionsprofil, og dermed for dennes adgang til Aula. Du kan også låse en låst bruger op igen. Når du låser en bruger, kan brugeren ikke længere logge ind i Aula for institutionen. Hvis brugeren allerede er logget ind, bliver brugeren logget ud. Låsningen gælder kun for den konkrete institutionsprofil. Hvis brugeren har andre ikke-låste institutionsprofiler, kan brugeren fortsat logge ind med disse.';
export const USERS_LOCK_USER_UNLOCKING_WARNING =
  'Brugerens institutionsprofil er låst op og kan atter tilgå Aula. Bemærk at der kan gå op til 15 minutter før brugeren låses igen.';
export const USERS_LOCK_USER_LOCKING_WARNING =
  'Brugerens institutionsprofil er låst og kan ikke tilgå Aula. Bemærk at der kan gå op til 15 minutter før brugeren kan låses op.';
export const USERS_LOCK_ADD_REASON = 'Angiv evt. årsag';
export const USERS_LOCK_MAX_CHARACTERS = 'Maks 500 karakterer';
export const USERS_LOCK_USER_PLACEHOLDER = 'Skriv her...';
export const USERS_LOCK_USER_TABLE_HEADER_DATE = 'Dato';
export const USERS_LOCK_USER_TABLE_HEADER_USER = 'Bruger';
export const USERS_LOCK_USER_TABLE_HEADER_INCIDENT = 'Hændelse';
export const USERS_LOCK_USER_TABLE_HEADER_CAUSE = 'Årsag';
export const USERS_LOCK_USER_CAUSE_SUBMIT = 'Lås bruger';
export const USERS_UNLOCK_USER_CAUSE_SUBMIT = 'Lås bruger op';
export const USERS_LOCK_USER_CAUSE_TITLE = 'Bekræft Låsning af bruger';
export const USERS_UNLOCK_USER_CAUSE_TITLE = 'Bekræft lås op af bruger';
export const USERS_FIELDS_CREATION_DATE = 'Oprettet';
export const USERS_FIELDS_START_END = 'Start og slutdato';
export const USERS_FIELDS_ASSOCIATED_DATA = 'Data er tilknyttet';
export const USERS_FIELDS_CATEGORY = 'Kategori';
export const USERS_FIELDS_TITLE = 'Titel';
export const USERS_FIELDS_DESCRIPTION = 'Beskrivelse';
export const USERS_FIELDS_CONTACT_PERSON = 'Oprettet af';
export const USERS_FIELDS_SENSITIVITY = 'Følsomhed';
export const USERS_FIELDS_ACTIONS = '';
export const USERS_FIELDS_MESSAGE_REVISIONS_CREATION_DATE = 'Dato';
export const USERS_FIELDS_MESSAGE_REVISIONS_THREAD_SUBJECT = 'Emne';
export const USERS_FIELDS_MESSAGE_REVISIONS_THREAD_PARTICIPANTS = 'Deltagere';
export const USERS_FIELDS_MESSAGE_REVISIONS_MESSAGE_CONTENT_BEFORE = 'Indhold før';
export const USERS_FIELDS_MESSAGE_REVISIONS_MESSAGE_CONTENT_AFTER = 'Indhold efter';
export const USERS_FIELDS_MESSAGE_REVISIONS_VERSIONS = 'Versioner';
export const USERS_LOAD_MORE_MESSAGE_REVISIONS = 'Hent flere';
export const USERS_LOAD_MORE_MESSAGE_REVISIONS_NO_MORE_RESULTS = 'Der er ikke flere beskeder';
export const USERS_MESSAGE_REVISIONS_NO_RESULTS = 'Der er ingen beskeder at vise';
export const USERS_MESSAGE_REVISIONS_MESSAGE_DELETED = '[Beskeden er slettet]';
export const USER_DATA_IS_DELETED = 'slettet';
export const USER_RIGHT_AND_ROLE_DESCRIPTION_MUNICIPAL = 'Kommunal administrator';
export const USER_RIGHT_AND_ROLE_DESCRIPTION_INSTITUTION = 'Institutions administrator';
export const USER_RIGHT_AND_ROLE_DESCRIPTION_OTHER = 'Andre rettigheder';
export const USER_RIGHT_AND_ROLE_DESCRIPTION_HEADER_TEXT = 'Oversigt over rettigheder';
export const USER_RIGHT = 'Rettighed';
export const USER_RIGHT_DESCRIPTION = 'Beskrivelse';
export const USER_RIGHT_OVERVIEW_OPEN = 'Åbn oversigt over rettigheder';
export const USER_BACK_TO_DETAIL = 'Tilbage til brugeroplysninger';
export const ALL_USER_DATA_HEADER = 'Data om';
export const ALL_USER_DATA_SUB_HEADER =
  'Her kan du se en oversigt over det forskellige data, som der er tilknyttet brugeren. Data er opdelt ift. de forskellige områder og moduler, der er i Aula.';
export const ALL_USER_DATA_SUB_HEADER_2 = 'Det er muligt at eksportere eller slette data om brugeren.';
export const ALL_USER_DATA_HEADER_TEXT_EVENTS = 'Begivenheder/Invitationer/Ferie';
export const ALL_USER_DATA_HEADER_TEXT_THREADS = 'Beskedtråde';
export const ALL_USER_DATA_HEADER_TEXT_MESSAGES = 'Slettede og redigerede beskeder';
export const ALL_USER_DATA_HEADER_TEXT_CONTACT_INFO = 'Supplerende stamdata/Deling af kontaktoplysninger/Kontaktinfo';
export const ALL_USER_DATA_HEADER_TEXT_GROUPS = 'Grupper';
export const ALL_USER_DATA_HEADER_TEXT_POSTS = 'Opslag';
export const ALL_USER_DATA_HEADER_TEXT_MEDIA = 'Medier';
export const ALL_USER_DATA_HEADER_TEXT_DOCUMENTS = 'Dokumenter';
export const ALL_USER_DATA_HEADER_TEXT_SUGGESTED_NAMES = 'Henteansvarlige';
export const ALL_USER_DATA_HEADER_TEXT_COMMENTS = 'Kommentarer';
export const ALL_USER_DATA_WRONG_DATE_FORMAT = 'Indtast korrekt dato format';
export const ALL_USER_DATA_START_DATE_IS_AFTER_END_DATE = 'Startdato skal være før slutdato';
export const ALL_USER_DATA_DATE_IS_INVALID = 'Dato er ugyldig';
export const SEARCH_RESULT_PROFILE_TYPE_daycare = 'Dagtilbudsbarn';
export const SEARCH_RESULT_PROFILE_TYPE_early_student = 'Indskolingselev';
export const SEARCH_RESULT_PROFILE_TYPE_middle_late_student = 'Mellemtrins-/Udskolingselev';
export const SEARCH_RESULT_PROFILE_TYPE_guardian = 'Forælder';
export const SEARCH_RESULT_PROFILE_TYPE_leader = 'Leder';
export const SEARCH_RESULT_PROFILE_TYPE_preschool_teacher = 'Pædagog';
export const SEARCH_RESULT_PROFILE_TYPE_teacher = 'Lærer';
export const SEARCH_RESULT_PROFILE_TYPE_other = 'Anden';

// RIGHT TO BE FORGOTTEN
export const RTBF_REQUESTS_PERIOD_DATE = 'Dato';
export const RTBF_REQUESTS_PERIOD_FROM = 'Fra';
export const RTBF_REQUESTS_PERIOD_TO = 'Til';
export const RTBF_REQUESTS_CREATOR_NAME = 'Oprettet af';
export const RTBF_REQUESTS_ENTITY_TYPE = 'Entitet';
export const RTBF_REQUESTS_OPERATION_TYPE = 'Operation';
export const RTBF_REQUESTS_STATUS = 'Status';
export const RTBF_REQUESTS_STATUS_TEXT = 'Kommentar';
export const RTBF_REQUESTS_ACTIONS = 'Handling';
export const RTBF_ENTITY_GROUPS = 'Grupper';
export const RTBF_ENTITY_COMMENTS = 'Kommentarer';
export const RTBF_ENTITY_MEDIA = 'Medier';
export const RTBF_ENTITY_DOCUMENTS = 'Dokumenter';
export const RTBF_ENTITY_POSTS = 'Opslag';
export const RTBF_ENTITY_THREADS = 'Beskeder';
export const RTBF_ENTITY_EVENTS = 'Begivenheder';
export const RTBF_ENTITY_ADDITIONALDATACONSENTCONTACTINFO =
  'Supplerende stamdata/Deling af kontaktoplysninger/Kontaktinfo';
export const RTBF_ENTITY_PICKUPRESPONSIBLES = 'Henteansvarlige';
export const RTBF_HANDLE_ENTITIES_SUCCESS = 'Eksport/Sletning blev oprettet';
export const RTBF_STATUS_FAILED = 'Fejlet';
export const RTBF_STATUS_DONE = 'Færdig';
export const RTBF_STATUS_BEING_PROCESSED = 'Under behandling';
export const RTBF_OPERATION_DELETE = 'Slet';
export const RTBF_OPERATION_EXPORT = 'Eksport';
export const ARIA_LABEL_USER_EDIT = 'Rediger brugertilladelser';
export const ARIA_LABEL_USERS_LABEL_SEARCH_USERS = 'Indtast blandt brugere';
export const ARIA_LABEL_USERS_SEARCH = 'Indtast nøglesøgning';

export const EXPORTS = 'Eksporteringer';
export const DELETION = 'Sletninger';

// MUNICIPAL ADMINISTRATOR RIGHT
export const MUNICIPAL_ADMINISTRATOR_HANDLE_RIGHT_TO_INSIGHT = 'Håndter ret til indsigt og ret til at blive glemt';
export const MUNICIPAL_ADMINISTRATOR_HANDLE_REPORTS = 'Håndter Anmeldelser og slet Indhold';
export const MUNICIPAL_ADMINISTRATOR_HANDLE_ALLOWED_RECIPIENTS = 'Håndter Godkendte Modtagere';
export const MUNICIPAL_ADMINISTRATOR_HANDLE_USER_ROLES = 'Håndter Brugerroller';
export const MUNICIPAL_ADMINISTRATOR_HANDLE_RESOURCES_MUNICIPALITY = 'Håndter Ressourcer på kommune';
export const MUNICIPAL_ADMINISTRATOR_HANDLE_COMMUNICATION_CHANNELS = 'Håndter Kommunikationskanaler';
export const MUNICIPAL_ADMINISTRATOR_HANDLE_SHARED_INBOX = 'Håndter Fællespostkasser';
export const MUNICIPAL_ADMINISTRATOR_HANDLE_ADMINISTRATIVE_AUTHORITY = 'Håndter Forvaltningsmyndigheder';
export const MUNICIPAL_ADMINISTRATOR_HANDLE_GROUPS_MUNICIPALITY = 'Håndter Grupper';
export const MUNICIPAL_ADMINISTRATOR_HANDLE_TRANSITION_YEAR = 'Håndter Overgangsår';
export const MUNICIPAL_ADMINISTRATOR_HANDLE_DASHBOARDS = 'Håndter Dashboard';
export const MUNICIPAL_ADMINISTRATOR_HANDLE_MUNICIPAL_FILES_TO_ESDH =
  'Håndter filer sendt til journalisering i ESDH-system';
export const MUNICIPAL_ADMINISTRATOR_DELETE_ACCESSIBLE_ALBUM = 'Slet albummer';

// INSTITUTION ADMINISTRATOR RIGHT
export const INSTITUTION_ADMINISTRATOR_HANDLE_REPORTS = 'Håndter Anmeldelser og slet Indhold';
export const INSTITUTION_ADMINISTRATOR_HANDLE_ADDITIONAL_MASTER_DATA = 'Håndter Supplerende Stamdata og Tilladelser';
export const INSTITUTION_ADMINISTRATOR_HANDLE_USER_ROLES = 'Håndter Brugerroller';
export const INSTITUTION_ADMINISTRATOR_HANDLE_RESOURCES_INSTITUTION = 'Håndter Ressourcer på institutionen';
export const INSTITUTION_ADMINISTRATOR_PRESENCE_PLANNING = 'Komme/Gå planlægning';
export const INSTITUTION_ADMINISTRATOR_HANDLE_SHARED_INBOX = 'Håndter Fællespostkasse';
export const INSTITUTION_ADMINISTRATOR_HANDLE_COMMON_FILES = 'Håndter Fælles Filer';
export const INSTITUTION_ADMINISTRATOR_HANDLE_GROUPS_INSTITUTION = 'Håndter Grupper';
export const INSTITUTION_ADMINISTRATOR_HANDLE_DASHBOARDS = 'Håndter Dashboards';
export const INSTITUTION_ADMINISTRATOR_HANDLE_PRESENCE = 'Håndter Komme/Gå';
export const INSTITUTION_ADMINISTRATOR_HANDLE_DELETED_SECURE_FILES = 'Håndter Slettede Sikre Filer';
export const INSTITUTION_ADMINISTRATOR_REVISION_HISTORY = 'Svarhistorik';
export const INSTITUTION_ADMINISTRATOR_EDIT_SHARED_ALBUMS = 'Rediger albummer';
export const INSTITUTION_ADMINISTRATOR_EDIT_SHARED_ALBUMS_DESCRIPTION =
  'Giver en medarbejder mulighed for at redigere alle albums denne har adgang til. Medarbejdere med denne rettighed kan skifte titel, redigere delinger, redigere beskrivelse, multi-tagge medier, redigere eller slette alle medier fra et album.';
export const INSTITUTION_ADMINISTRATOR_DELETE_ACCESSIBLE_ALBUM = 'Slet albummer';

// OTHER ADMINISTRATOR RIGHT
export const OTHER_ADMINISTRATOR_BOARD_MEMBER = 'Bestyrelsesmedlem';
export const OTHER_ADMINISTRATOR_USE_GROUPS_AS_DISTRIBUTION_LISTS = 'Brug grupper som distributionsliste';
export const OTHER_ADMINISTRATOR_EXPORT_SECURE_FILES = 'Eksportering sikre filer';
export const OTHER_ADMINISTRATOR_SEE_CONTACT_PARENTS_CONTACT_INFO = 'Kontaktforælder';
export const OTHER_ADMINISTRATOR_INSTITUTION_CALENDAR_ADMIN = 'Opret begivenheder kun i institutionens kalender';
export const OTHER_ADMINISTRATOR_CREATE_PARENTAL_PERFORMANCE_MEETINGS = 'Opret kalendersamtaler';
export const OTHER_ADMINISTRATOR_ASSOCIATE_SECURE_DOCUMENTS_TO_ALL_GROUPS = 'Relater sikre filer til alle grupper';
export const OTHER_ADMINISTRATOR_VIEW_NAME_PROTECTION = 'Se navnebeskyttelse';
export const OTHER_ADMINISTRATOR_VIEW_PERSONAL_REFERENCE_DATA_FOR_ALL_CHILDREN_AND_GUARDIAN =
  'Se stamkortoplysninger for alle på institutionen';
export const OTHER_ADMINISTRATOR_SECURE_DOCUMENTS_ACCESS_ALL = 'Sikker fildeling, fuld institutionel adgang';
export const OTHER_ADMINISTRATOR_VIEW_CONTACT_INFORMATION_ALL = 'Se kontaktoplysninger for alle på institutionen';
export const OTHER_ADMINISTRATOR_ACCESS_GOOGLE_DRIVE = 'Tilgå Google Drive';
export const OTHER_ADMINISTRATOR_ACCESS_ONEDRIVE = 'Tilgå OneDrive';
export const OTHER_ADMINISTRATOR_HANDLE_OTHERS_EVENTS = 'Håndter begivenheder';
export const OTHER_ADMINISTRATOR_JOURNALIZING_TO_ESDH_SYSTEM = 'Journalisering i ESDH-system';

// MUNICIPAL ADMINISTRATOR RIGHT DESCRIPTION
export const MUNICIPAL_ADMINISTRATOR_DESCRIPTION_HANDLE_RIGHT_TO_INSIGHT =
  'Giver mulighed for at slette al data om en bruger samt at fremsøge både aktive og inaktive brugere i Aula. OBS! Denne administratorrettighed skal tildeles separat og følger således ikke med, når en medarbejder tildeles rollen ”Kommunal administrator”. Rettigheden kan kun tildeles en bruger på kommuneinstitutionen.';
export const MUNICIPAL_ADMINISTRATOR_DESCRIPTION_HANDLE_REPORTS =
  'Rettigheden ”Håndter anmeldelser og slet indhold” giver mulighed for at håndtere anmeldelser på den kommunale institution. Herunder slette anmeldte opslag, medier og kommentarer.';
export const MUNICIPAL_ADMINISTRATOR_DESCRIPTION_HANDLE_ALLOWED_RECIPIENTS =
  'Giver mulighed for at håndtere, hvilke eksterne modtagere, Aula kan sende personfølsomt indhold til. Denne rettighed er endnu ikke færdigudviklet, hvorfor den på nuværende tidspunkt ikke har nogen funktion.';
export const MUNICIPAL_ADMINISTRATOR_DESCRIPTION_HANDLE_USER_ROLES =
  'Giver mulighed for at søge efter samt tildele roller og rettigheder til alle brugere i kommunen. Der kan søges på – og tildeles roller/rettigheder til - både kommunale medarbejdere og brugere på en institution';
export const MUNICIPAL_ADMINISTRATOR_DESCRIPTION_HANDLE_RESOURCES_MUNICIPALITY =
  'Giver rettighed til at oprette ressourcer (lokaler, læringsmaterialerb mm.) på institutionelt- eller kommunalt niveau. Derudover kan der gives specifikke rettigheder for bookning af en eller flere specifikke ressourcer i kalenderen til brugere og/eller grupper.';
export const MUNICIPAL_ADMINISTRATOR_DESCRIPTION_HANDLE_COMMUNICATION_CHANNELS =
  'Kan defineres på kommunalt eller institutionelt niveau, og kan blokere visse profiltyper fra at kommunikere med andre profiltyper (f.eks. Elever til forældre eller forældre til medarbejdere).';
export const MUNICIPAL_ADMINISTRATOR_DESCRIPTION_HANDLE_SHARED_INBOX =
  'En fællespostkasse er en mappe inde i beskedmodulet, hvorfra tilføjede medarbejdere kan sende, modtage og besvare beskeder. Håndtering af fællespostkasser giver mulighed for at oprette fællespostkasser, samt redigere og slette eksisterende fællespostkasser.';
export const MUNICIPAL_ADMINISTRATOR_DESCRIPTION_HANDLE_ADMINISTRATIVE_AUTHORITY =
  'Giver mulighed for at gruppere institutioner i forvaltningsmyndigheder, eller oprette en enkelt institution som en forvaltningsmyndighed.';
export const MUNICIPAL_ADMINISTRATOR_DESCRIPTION_HANDLE_GROUPS_MUNICIPALITY =
  'Giver mulighed for at håndtere (herunder oprette og redigere) grupper på kommunalt niveau. Det indebærer også håndtering af tværgående grupper.';
export const MUNICIPAL_ADMINISTRATOR_DESCRIPTION_HANDLE_TRANSITION_YEAR =
  'Overgangsår angiver, hvornår en elev går fra at være indskolingselev til at være elev på mellemtrin og derefter udskoling. Dette kan defineres for hele kommunen samt på den enkelte institution.';
export const MUNICIPAL_ADMINISTRATOR_DESCRIPTION_HANDLE_DASHBOARDS =
  'Dashboard er en betegnelse for de moduler og widgets, som brugerne har til rådighed i deres Aula – eksempelvis Besked- eller Kalendermodulet. Der er mulighed for at konfigurere dashboards for: Indskolingselever, Mellemtrins-/udskolingselever, Forældre/Værger, Pædagogisk personale (Skole), Pædagogisk personale (Dagtilbud), Ledere, samt Øvrige brugere.';
export const MUNICIPAL_ADMINISTRATOR_DESCRIPTION_HANDLE_MUNICIPAL_FILES_TO_ESDH =
  'Giver medarbejdere mulighed for at se de sikre filer, der blev sendt til journalisering i ESDH-systemet, hvor journalisering fejlede. Rettigheden skal tildeles af en administrator.';
export const MUNICIPAL_ADMINISTRATOR_DESCRIPTION_DELETE_ACCESSIBLE_ALBUM =
  'Giver medarbejderen mulighed for at slette albummer, som de har adgang til i Aula. Rettigheden skal tildeles af en administrator.';
// INSTITUTION ADMINISTRATOR RIGHT DESCRIPTION_
export const INSTITUTION_ADMINISTRATOR_DESCRIPTION_HANDLE_REPORTS =
  'Forskelligt indhold såsom opslag kan anmeldes af brugere i Aula. Håndtering af anmeldelser giver mulighed for at se anmeldelser, håndtere anmeldelser, slette opslag, medier og kommentarer samt modtage notifikationer ved nye anmeldelser.';
export const INSTITUTION_ADMINISTRATOR_DESCRIPTION_HANDLE_ADDITIONAL_MASTER_DATA =
  'Giver mulighed for at oprette Supplerende stamdata omkring institutionens børn, samt tilladelser som forældrene kan besvare.';
export const INSTITUTION_ADMINISTRATOR_DESCRIPTION_HANDLE_USER_ROLES =
  'Giver mulighed for at søge efter alle brugere og grupper på institutionen i administrationsmodulet samt tildele brugere og grupper roller og rettigheder.';
export const INSTITUTION_ADMINISTRATOR_DESCRIPTION_HANDLE_RESOURCES_INSTITUTION =
  'Giver rettighed til at oprette ressourcer (lokaler, læringsmaterialer mm.) på institutionelt niveau, og der kan gives specifikke rettigheder for bookningi kalendere til brugere og/eller grupper på institutionsniveau.';
export const INSTITUTION_ADMINISTRATOR_DESCRIPTION_PRESENCE_PLANNING =
  'Giver mulighed for at anmode om ferieregistrering fra forældre, der har Komme/Gå modulet tilgængeligt. Derudover er det muligt at se statistik på fravær af børn, der har Komme/Gå modulet tilknyttet.';
export const INSTITUTION_ADMINISTRATOR_DESCRIPTION_REVISION_HISTORY =
  'Giver mulighed for at se svarhistorikken for deling af kontaktoplysinger, stamkortoplysninger og tilladelser.';
export const INSTITUTION_ADMINISTRATOR_DESCRIPTION_HANDLE_SHARED_INBOX =
  'En fællespostkasse er en mappe inde i beskedmodulet, hvor tilføjede medarbejdere kan sende, modtage og besvare beskeder fra. Håndtering af fællespostkasser giver mulighed for at oprette fællespostkasser, samt redigere og slette eksisterende fællespostkasser.';
export const INSTITUTION_ADMINISTRATOR_DESCRIPTION_HANDLE_COMMON_FILES =
  'Giver mulighed for at se, redigere og oprette Fælles filer. En Fælles fil er eksempelvis et dokument, som kan oprettes og distribueres til særlige målgrupper/profiltyper i Aula. Eksempelvis ”Mobbepolitik” til medarbejdere og forældre, ”Brandinstruks” til medarbejdere mm.';
export const INSTITUTION_ADMINISTRATOR_DESCRIPTION_HANDLE_GROUPS_INSTITUTION =
  'Giver mulighed for at søge, oprette og redigere i alle grupper på institutionen.';
export const INSTITUTION_ADMINISTRATOR_DESCRIPTION_HANDLE_DASHBOARDS =
  '’Dashboard’ er en betegnelse for de moduler og widgets, som brugerne har til rådighed i deres Aula, eksempelvis Besked- eller Kalendermodulet. Der er mulighed for at konfigurere dashboards for: Indskolingselever, Mellemtrins-/udskolingselever, Forældre/Værger, Pædagogisk personale (Skole), Pædagogisk personale (Dagtilbud), Ledere, samt Øvrige brugere.';
export const INSTITUTION_ADMINISTRATOR_DESCRIPTION_HANDLE_PRESENCE =
  'Giver adgang til at konfigurere Komme/Gå modulet, at opsætte institutionens Komme/Gå på en fysisk enhed på institutionen (Check-In tavle) samt at oprette Fysiske Placeringer.';
export const INSTITUTION_ADMINISTRATOR_DESCRIPTION_HANDLE_DELETED_SECURE_FILES =
  'Giver mulighed for at tilgå og gendanne slettede sikre filer fra din institution.';
export const INSTITUTION_ADMINISTRATOR_DESCRIPTION_DELETE_ACCESSIBLE_ALBUM =
  'Giver medarbejderen mulighed for at slette albummer, som de har adgang til i Aula. Rettigheden skal tildeles af en administrator.';
// OTHER ADMINISTRATOR RIGHT DESCRIPTION
export const OTHER_ADMINISTRATOR_DESCRIPTION_BOARD_MEMBER =
  'En elev eller en forælder kan tildeles denne rettighed, som giver de samme muligheder som en kontaktforælder. Derudover kan bestyrelsesmedlemmer tilgå en liste over kontaktforældrenes kontaktinformationer.';
export const OTHER_ADMINISTRATOR_DESCRIPTION_USE_GROUPS_AS_DISTRIBUTION_LISTS =
  'Giver brugere af profiltypen medarbejder, forælder eller elev mulighed for at oprette opslag til, sende kalenderinvitationer til, sende beskeder til, og dele albums med grupper på institutionen, selvom de ikke selv er medlem af gruppen.';
export const OTHER_ADMINISTRATOR_DESCRIPTION_EXPORT_SECURE_FILES =
  'Giver medarbejdere mulighed for at eksportere sikre filer ud af Aula, så de kan sendes til eksterne systemer/personer. Er der tale om en enkelt fil, eksporteres den direkte som PDF, og er der flere filer eller vedhæftninger, eksporteres de som zip-mapper med undermapper pr. sikker fil.';
export const OTHER_ADMINISTRATOR_DESCRIPTION_SEE_CONTACT_PARENTS_CONTACT_INFO =
  'Giver en bruger af profiltypen ”Forælder” mulighed for at tilføje en beskrivelse på sin profil. Brugeren vil også blive tilføjet til en liste af kontaktforældre, som Bestyrelsesmedlemmer har rettighed til at se.';
export const OTHER_ADMINISTRATOR_DESCRIPTION_INSTITUTION_CALENDAR_ADMIN =
  'Giver mulighed for at oprette begivenheder, der udelukkende bliver vist i skolens kalender og ikke i brugerens egen kalender. Rettigheden tildeles af en administrator, og kan tildeles profiltyperne medarbejder, pædagog og leder på en skole eller et dagtilbud, dvs. ikke på den kommunale institution.';
export const OTHER_ADMINISTRATOR_DESCRIPTION_CREATE_PARENTAL_PERFORMANCE_MEETINGS =
  'En medarbejder, hvis profiltype er ’Anden’, og dermed ikke pr. default kan oprette samtaler, kan tildeles denne rettighed. Rettigheden giver mulighed for at kunne oprette samtaler via kalenderen.';
export const OTHER_ADMINISTRATOR_DESCRIPTION_ASSOCIATE_SECURE_DOCUMENTS_TO_ALL_GROUPS =
  'Giver en medarbejder mulighed for at fremsøge alle institutionens grupper i ”omhandler gruppe”- feltet. Det er dermed muligt at oprette sikre filer på alle grupper indenfor institutionen. Rettigheden kan kun tildeles medarbejdere.';
export const OTHER_ADMINISTRATOR_DESCRIPTION_VIEW_NAME_PROTECTION =
  'Giver brugere med profiltypen ’medarbejdere’ mulighed for at se, om en bruger er navnebeskyttet eller ej. Dette sker gennem rettigheden ’Se navnebeskyttelse’. Alle medarbejdere med rollerne ’Leder’, ’Ledelse’, ’Lærer’, ’Pædagog’, ’Konsulent’ bliver automatisk tildelt denne rettighed.';
export const OTHER_ADMINISTRATOR_DESCRIPTION_VIEW_PERSONAL_REFERENCE_DATA_FOR_ALL_CHILDREN_AND_GUARDIAN =
  'Giver medarbejdere mulighed for at se en liste over alle stamkortoplysninger for alle børn og forældre indenfor institutionen. Stamkortoplysninger omfatter tilladelser, supplerede stamdata og samtykker.';
export const OTHER_ADMINISTRATOR_DESCRIPTION_SECURE_DOCUMENTS_ACCESS_ALL =
  'Giver medarbejdere mulighed for at se, redigere og slette alle sikre filer på institutionen, uanset om de er delt med brugeren eller ej. Rettigheden skal tildeles af en administrator og kan kun tildeles til medarbejdere på en skole eller et dagtilbud, dvs. ikke på den kommunale institution.';
export const OTHER_ADMINISTRATOR_DESCRIPTION_VIEW_CONTACT_INFORMATION_ALL =
  'Med denne rettighed får medarbejdere mulighed for at se en liste over kontaktoplysninger på brugere i grupper, de ikke selv er en del af.';
export const OTHER_ADMINISTRATOR_DESCRIPTION_ACCESS_GOOGLE_DRIVE =
  'Giver mulighed for at tilgå henholdsvis Google Drive – to fildelingsløsninger, som i Aula tilgås via Dokumentmodulet, hvis rettighederne er tildelt. Det er muligt at tildele rettigheden til både medarbejdere, forældre og elever. ';
export const OTHER_ADMINISTRATOR_DESCRIPTION_ACCESS_ONEDRIVE =
  'Giver mulighed for at tilgå henholdsvis OneDrive – to fildelingsløsninger, som i Aula tilgås via Dokumentmodulet, hvis rettighederne er tildelt. Det er muligt at tildele rettigheden til både medarbejdere, forældre og elever.';
export const OTHER_ADMINISTRATOR_DESCRIPTION_HANDLE_OTHERS_EVENTS =
  'Giver en medarbejder mulighed for at kunne slette og redigere andre medarbejderes begivenheder og samtaler på institutionen. Redigering er begrænset til sted, ressourcer og ekstra lokaler. Andre medarbejderes begivenheder og samtaler ses ved at åbne deres kalender.';
export const OTHER_ADMINISTRATOR_DESCRIPTION_JOURNALIZING_TO_ESDH_SYSTEM =
  'Giver medarbejdere mulighed for at journalisere sikre filer i kommunens ESDH-system. Rettigheden skal tildeles af en administrator.';

export const DESCRIPTION_ROLES_AND_RIGHTS =
  'Nedenstående rettigheder gives på hele institutionen. Rettigheder kan gives enten individuelt eller igennem en gruppe';
export const INDIVIDUAL_USER_RIGHTS = 'Individuelle brugerrettigheder';
export const GROUP_MEMBERSHIP_RIGHTS = 'Brugerrettigheder fra gruppemedlemskab';
export const RIGHTS_IN_GROUPS = 'Rettigheder i grupper';
export const GROUP = 'Gruppe';
export const WRITE_GROUP_POSTS = 'Skrive gruppeopslag';
export const CREATE_EVENTS = 'Oprette begivenheder';
export const SHARE_MEDIA = 'Dele medier';
export const CREATE_MESSAGES = 'Oprette beskeder';
export const NO_ASSIGNED_ROLES = 'Ingen tildelte';
export const DELETE_ROLE_WARNING = 'Du er ved at fjerne en rolle eller rettighed fra personen.';
export const DELETE_ROLE_CONFIRMATION = 'Er du sikker på, at du vil fortsætte?';
