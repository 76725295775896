import { portal } from '../../assets/plugins/axios/axios';
import { HttpError } from '../../../src/business/httpError';
import { AxiosError } from 'axios';

class CentralConfigurationService {
  public getMaxFileSize = async (): Promise<Number> => {
    try {
      const { data } = await portal.get('?method=centralConfiguration.getMaxFileSize');
      return data.data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };
}

export const centralConfigurationService = new CentralConfigurationService();
