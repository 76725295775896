















import AulaButton from './AulaButton.vue';
import { imageExtensions } from '../enums/imageExtensions';
import { videoExtensions } from '../enums/videoExtensions';
import { soundExtensions } from '../enums/soundExtensions';

export default {
  components: { AulaButton },
  props: {
    multiple: { type: Boolean, default: true },
    formats: { type: Array, default: () => [...imageExtensions, ...videoExtensions, ...soundExtensions] },
  },
  emits: ['change'],
  computed: {
    acceptedFormats() {
      return this.formats.map(format => `.${format}`).join(', ');
    },
  },
  methods: {
    handleFileSelection(event) {
      this.$emit('change', event.target.files);
      this.$refs.fileInput.value = null;
    },
    openFilePicker() {
      this.$refs.fileInput.click();
    },
  },
};
